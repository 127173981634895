import { collection, query, orderBy, addDoc, where, doc, serverTimestamp } from "firebase/firestore";
import { db } from '../../firebase';


export const createNewPostTest = async (testDoc) => {
    const q = collection(db, "postTests");

    try {

        let Qsnapshot = await addDoc(q, {
            ...testDoc,
            updatedTime: serverTimestamp(),
            postTime: serverTimestamp(),
        }).then(() => {
            // setUpdatedProfile(true)
            console.log('New Test Created!');
            alert(
                'New Test Created!',
            );
        })
    }
    catch (error) {
        alert(error);
    }

}