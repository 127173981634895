import React, { useEffect, useState } from 'react';
import './Ting.css';

// Components
import EditTing from '../../../../components/Ting/EditTing';
import Media from '../../../../components/Ting/Media';


//Button
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import { getTing } from '../../../../utils/firebase/getTing';
import { handleTingUpdate } from '../../../../utils/firebase/updateTing';
import useTingStore from '../../../../stores/tingStore';

export default function Ting() {
    const tingDoc = useTingStore(state => state.tingDoc);
    const setTingDoc = useTingStore(set => set.setTingDoc);
    const [loading, setLoading] = React.useState(true);
    const [saving, setSaving] = useState(false);
    const tingId = window.location.pathname.split('/')[2]


    useEffect(() => {
        assignUser()
    }, [])

    const assignUser = async (userId) => {
        const tingDocData = await getTing(window.location.pathname.split('/')[2]);
        setTingDoc(tingDocData.tingDocDataReceived);
        setLoading(tingDocData.loaded)

    }

    const handleSave = async () => {
        setSaving(true);
        console.log('tingDoc:', tingDoc);
        await handleTingUpdate(tingDoc, tingId);
        setSaving(false);
    };

    return (
        <>
        <div className='ting__container'>
            <div className='ting__heading'>
                Edit
            </div>
            <div className='ting__button'>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSave}
                    disabled={saving}
                >
                    {saving ? <CircularProgress size={24} /> : 'Save Changes'}
                </Button>
            </div>

            {!loading && (
                <>
                    <Media />
                    <EditTing />
                </>
            )}
        </div>
    </>

    );
}