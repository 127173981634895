import useTestStore from '../../../stores/testStore';
import React, { useState } from 'react'
import moment from 'moment';

const UploadVideo = () => {
    const newTestDoc = useTestStore(state => state.newTestDoc);
    const setNewTestDoc = useTestStore(set => set.setNewTestDoc);

    const [image, setImage] = useState("");
    const [url, setUrl] = useState("");
    const [uploading, setUploading] = useState(false);


    const uploadImage = () => {
        setUploading(true);
        console.log('uploading image');
        const data = new FormData()
        data.append("file", image)
        data.append("upload_preset", "ml_default")
        data.append("cloud_name", "latest-sightings-upload")
        data.append('folder', `/postTests/${moment().format('YYYY')}/${moment().format('MMMM')}/${newTestDoc.testTitle}/`);
        data.append('public_id', `-${Math.random().toString(36).substring(2)}-${newTestDoc.postVideo.substring(newTestDoc.postVideo.lastIndexOf('/') + 1)}`);

        fetch(`https://api.cloudinary.com/v1_1/latest-sightings-upload/auto/upload/`, {
            method: "post",
            body: data
        })
            .then(resp => resp.json())
            .then(data => {
                console.log('data', data);
                setUrl(data.url)
                setNewTestDoc({ ...newTestDoc, postVideo: data.url, imageHeight: data.height, imageWidth: data.width });
                setUploading(false);

            })
            .catch(err =>{setUploading(false); console.log(err)})
    }
    return (
        <div>
            <div>
                <br />
                <br />
                <input type="file" accept="video/*"
                    onChange={(e) => {
                        setImage(e.target.files[0])
                        setUrl(null)
                    }
                    } ></input>
                <button onClick={uploadImage}
                    style={{ borderWidth: '0px', backgroundColor: 'white', color: 'blue', textDecoration: 'underline', borderColor: 'black' }}>Upload</button>
            </div>

            {uploading &&
                <><br /><br /><br /><p>Uploading...</p></>}
            <div>
                <h1>Uploaded video will be displayed here:</h1>
                <video src={url} />
            </div>
        </div>
    )
}
export default UploadVideo;