import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import styled from "styled-components";


import useTestStore from '../../stores/testStore';
const ztable = require('ztable');

// Styled component for the data container
const DataContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  margin-left: 20px;
  gap: 100px;
`;

// Styled component for the data title
const DataTitle = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
`;

// Styled component for the data item container
const DataItem = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 5px;
  color: white;
  font-weight: bold;
  background-color: 'black';
`;

// Styled component for the data item key
const DataItemKey = styled.div`
  background-color: gray;
  padding: 5px;
  color: white;
  border-radius: 5px;
  width: 45px;
`;

// Styled component for the data item value
const DataItemValue = styled.div`
background-color: ${props => props.bgColor};
padding: 5px;
color: white;
border-radius: 5px;
max-width: 60px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Table = styled.div`
  display: table;
`;

const TableRow = styled.div`
  display: table-row;
`;

const TableCell = styled.div`
  display: table-cell;
  padding: 8px;
  width: 50px;  
`;


export default function TestVariationDetails() {
    const testDoc = useTestStore(state => state.testDoc);
    const setTestDoc = useTestStore(state => state.setTestDoc);

    const [highestCTR, setHighestCTR] = React.useState(null);
    const [lowestCTR, setLowestCTR] = React.useState(null);
    const [standardDeviation, setStandardDeviation] = React.useState(null);
    const [pValueHighest, setPValueHighest] = React.useState(0);
    const [pValueLowest, setPValueLowest] = React.useState(0);

    const [title0Mean, setTitle0Mean] = React.useState(null);
    const [title1Mean, setTitle1Mean] = React.useState(null);
    const [title2Mean, setTitle2Mean] = React.useState(null);
    const [thumbnail0Mean, setThumbnail0Mean] = React.useState(null);
    const [thumbnail1Mean, setThumbnail1Mean] = React.useState(null);
    const [thumbnail2Mean, setThumbnail2Mean] = React.useState(null);

    const [combinations, setCombinations] = React.useState([]);

    const handleGetCombinations = () => {
        let tempCombinations = [];
        const titles = testDoc.titles.length;
        const thumbnails = testDoc.thumbnails.length;
        const numCombinations = titles * thumbnails;
        for (let i = 0; i < titles; i++) {
            for (let j = 0; j < thumbnails; j++) {
                const currentCombination = `${i}${j}`;
                tempCombinations.push(currentCombination);
            }
        }
        setCombinations(tempCombinations);
    }

    const handleGetCTRs = () => {
        let CTRsObjectTemp = {};
        Object.keys(testDoc.segmentedViews).map((key, index) => {
            const currentCTR = (testDoc.segmentedViews[key] / testDoc.segmentedLoads[key]) * 100;
            CTRsObjectTemp[key] = currentCTR
        })
        console.log('CTRsObjectTemp', CTRsObjectTemp);

        const mean = Object.values(CTRsObjectTemp).reduce((a, b) => a + b) / Object.values(CTRsObjectTemp).length;
        console.log('mean', mean);
        const standardDeviation = (Math.sqrt(Object.values(CTRsObjectTemp).map(x => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / Object.values(CTRsObjectTemp).length)).toFixed(2);
        console.log('standardDeviation', standardDeviation);

        setStandardDeviation(standardDeviation);

        const highestCTR = Math.max(...Object.values(CTRsObjectTemp));
        const lowestCTR = Math.min(...Object.values(CTRsObjectTemp));

        for (const [key, value] of Object.entries(CTRsObjectTemp)) {
            if (value === highestCTR) {
                setHighestCTR(key);
            }
            else if (value === lowestCTR) {
                setLowestCTR(key);
            }
        }

        // get p value of highestCTR vs lowestCTR 
        const zScoreHighest = (highestCTR - mean) / standardDeviation;
        console.log('zScoreHighest', zScoreHighest);

        if (zScoreHighest) {
            setPValueHighest(1 - ztable(zScoreHighest));
        } else {
            setPValueHighest(0);
        }


        const zScoreLowest = (lowestCTR - mean) / standardDeviation;
        console.log('zScoreLowestest', zScoreLowest);
        if (zScoreLowest) {
            setPValueLowest(ztable(zScoreLowest));
        } else {
            setPValueLowest(0);
        }


    }

    const handleGetTitleAndThumbnailMeans = () => {
        let title0Combos = ['00', '01', '02'];
        let title1Combos = ['10', '11', '12'];
        let title2Combos = ['20', '21', '22'];

        let thumbnail0Combos = ['00', '10', '20'];
        let thumbnail1Combos = ['01', '11', '21'];
        let thumbnail2Combos = ['02', '12', '22'];

        let title0CTR = 0;
        let title1CTR = 0;
        let title2CTR = 0;

        let thumbnail0CTR = 0;
        let thumbnail1CTR = 0;
        let thumbnail2CTR = 0;

        title0Combos.map((key, index) => {
            title0CTR += testDoc.segmentedViews[key] / testDoc.segmentedLoads[key];
        })
        title1Combos.map((key, index) => {
            title1CTR += testDoc.segmentedViews[key] / testDoc.segmentedLoads[key];
        }
        )
        title2Combos.map((key, index) => {
            title2CTR += testDoc.segmentedViews[key] / testDoc.segmentedLoads[key];
        }
        )

        thumbnail0Combos.map((key, index) => {
            thumbnail0CTR += testDoc.segmentedViews[key] / testDoc.segmentedLoads[key];
        }
        )
        thumbnail1Combos.map((key, index) => {
            thumbnail1CTR += testDoc.segmentedViews[key] / testDoc.segmentedLoads[key];
        }
        )
        thumbnail2Combos.map((key, index) => {
            thumbnail2CTR += testDoc.segmentedViews[key] / testDoc.segmentedLoads[key];
        }
        )

        // Convert number to string
        title0CTR = title0CTR.toString();
        // Keep only first 5 characters
        title0CTR = title0CTR.substring(0, 5);

        setTitle0Mean(((title0CTR / 3).toFixed(4) * 100).toString().substring(0, 5));
        setTitle1Mean(((title1CTR / 3).toFixed(4) * 100).toString().substring(0, 5));
        setTitle2Mean(((title2CTR / 3).toFixed(4) * 100).toString().substring(0, 5));

        setThumbnail0Mean(((thumbnail0CTR / 3).toFixed(4) * 100).toString().substring(0, 5));
        setThumbnail1Mean(((thumbnail1CTR / 3).toFixed(4) * 100).toString().substring(0, 5));
        setThumbnail2Mean(((thumbnail2CTR / 3).toFixed(4) * 100).toString().substring(0, 5));
    }


    React.useEffect(() => {
        // handleGetCombinations();
        handleGetCTRs();
        handleGetTitleAndThumbnailMeans();
    }, [])

    const SegmentedData = ({ data, label }) => {
        const dataEntries = Object.entries(data);

        // Get average watchtime from data and extraData
        // const averageWatchtime = (data / extraData).toFixed(1);

        const colorScale = Math.max(...Object.values(data));
        console.log('colorScale', colorScale);
        // Sort the data by combinations 
        const sortedData = dataEntries.sort((a, b) => combinations.indexOf(a[0]) - combinations.indexOf(b[0]));
        // Sort by highest ctr 
        console.log('');
        return (
            <>
                <Container>
                    <DataTitle>{label}</DataTitle>

                    <Table>
                        {sortedData.map((entry) => (
                            <TableRow key={entry[0]}>
                                <TableCell>
                                    <DataItemKey>{entry[0]}</DataItemKey>
                                </TableCell>
                                < TableCell >
                                    <DataItemValue bgColor={`rgb(${200 - (entry[1] / colorScale) * 200}, ${entry[1] / colorScale * 200}, 0)`}
                                        style={{ 
                                            border: data[entry[0]].substring(0, 5) === colorScale.toString().substring(0, 5) ? '5px solid black' : 'none'
                                        }}
                                    >
                                        {data[entry[0]]}
                                    </DataItemValue>
                                </TableCell>
                            </TableRow>
                        ))}
                    </Table>
                </Container>

            </>
        );
    };


    return (
        <>
            <div style={{ marginTop: '20px' }} />
            <div >
                Significance Test Results
            </div>
            {/* line break */}
            <div style={{ marginTop: '20px' }} />

            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                }}
            >
                {/* Show the winning title */}
                {highestCTR &&
                    <>
                        <div>
                            <text>
                                {testDoc.titles[highestCTR[0]]}
                            </text>
                        </div>
                        <div style={{ marginTop: '20px' }} />

                        <div>
                            <img
                                src={testDoc.thumbnails[highestCTR[1] ?? 0]}
                                alt="thumbnail"
                                style={{ width: testDoc.imageWidth / 4, height: testDoc.imageHeight / 4 }}
                            />
                        </div>
                    </>}

            </Box>


            <DataContainer>
                <div style={{ display: 'flex', flexDirection: 'column', }}>
                    <DataTitle>Standard Deviation</DataTitle>
                    <DataItemKey>{standardDeviation}</DataItemKey>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <DataTitle>Highest CTR</DataTitle>
                    <DataItemKey>{highestCTR}</DataItemKey>
                    <DataTitle>Probability</DataTitle>
                    <DataItemKey style={{ backgroundColor: pValueHighest < 0.05 ? 'green' : 'grey' }}> {(pValueHighest.toFixed(2) * 100).toString().substring(0, 5)}%</DataItemKey>
                    <div >{pValueHighest < 0.05 ? 'Significant' : 'Not significant'}</div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <DataTitle>Lowest CTR</DataTitle>
                    <DataItemKey>{lowestCTR}</DataItemKey>
                    <DataTitle>Probability</DataTitle>
                    <DataItemKey style={{ backgroundColor: pValueLowest < 0.05 ? 'red' : 'grey' }}>{(pValueLowest.toFixed(2) * 100).toString().substring(0, 5)}%</DataItemKey>
                    <div >{pValueLowest < 0.05 ? 'Significant' : 'Not significant'}</div>
                </div>
            </DataContainer>

            <DataContainer>
                { title0Mean && title1Mean && title2Mean && <SegmentedData data={[title0Mean, title1Mean, title2Mean]} label="Title CTRs" />}
                { thumbnail0Mean && thumbnail1Mean && thumbnail2Mean && <SegmentedData data={[thumbnail0Mean, thumbnail1Mean, thumbnail2Mean]} label="Thumbnail CTRs" />}
            </DataContainer>

        </>
    );
}