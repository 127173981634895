import React, { useState } from 'react';
import './ContentItem.css';
import LoadingButton from '@mui/lab/LoadingButton'; // Import LoadingButton from Material-UI

const ContentItem = ({ item, onZoom, onPostToInstagram, postingToInstagramStories, setPostingToInstagramStories }) => {

    const handlePostToInstagram = async () => {
        await onPostToInstagram(item.postVideo, 'video', item.animal, item.roadname, item?.adminTingedForWho ?? item.userData?.username, item.number, item.tingTime);
        setPostingToInstagramStories(false); // Set loading to false once the post is complete
    };

    return (
        <div className='grid__item' style={{
            backgroundColor: `rgba(255, 0, 0, ${Math.min(item.likesCount / 10, 1)})`
        }}>
            {item.postVideo && (
                <LoadingButton
                    onClick={handlePostToInstagram}
                    loading={postingToInstagramStories} // Pass loading state to LoadingButton
                    variant="contained"
                    className='post-instagram-button'
                >
                    Post to Instagram Stories
                </LoadingButton>
            )}
            {item.postImg && (
                <img
                    src={item.postImg}
                    alt={item.animal}
                    className='grid__image'
                    style={{
                        aspectRatio: `${item.imageWidth} / ${item.imageHeight}`
                    }}
                    onClick={onZoom}
                />
            )}
            {item.postVideo && (
                <video
                    controls
                    className='grid__video'
                    style={{
                        aspectRatio: `${item.imageWidth} / ${item.imageHeight}`
                    }}
                >
                    <source src={item.postVideo} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            )}
            <div className='overlay'>
                <div className='grid__likes'>{item.likesCount} Likes</div>
                <div className='grid__comments'>{item.commentsCount} Comments</div>
                <div className='grid__timeago'>{item.timeAgo}</div>
            </div>
        </div>
    );
};

export default ContentItem;
