import React from 'react';
import './HighlightedParks.css';

const HighlightedParks = ({ parks, onParkClick, activePark }) => {
  return (
    <div className="highlighted-parks">
      <h3>Highlighted Parks</h3>
      <ul>
        {Object.keys(parks).map(park => (
          <li key={park} onClick={() => onParkClick(park)} className={activePark === park ? 'active' : ''}>
            {park} ({parks[park].length})
          </li>
        ))}
      </ul>
    </div>
  );
};

export default HighlightedParks;
