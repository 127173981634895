// ContentList.js
import React, { useEffect, useState, useCallback } from 'react';
import { collection, getDocs, orderBy, query, where } from 'firebase/firestore';
import { db } from '../../../../firebase';
import ContentItem from '../ContentItem/ContentItem';
import HighlightedParks from '../HighlightedParks/HighlightedParks';
import Image from 'react-image-enlarger';
import { postToInstagramStories, generateCaption } from '../../../../utils/instagram/postToInstagran';
import './ContentList.css';

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';



const ContentList = ({ filters }) => {
  const [content, setContent] = useState([]);
  const [loading, setLoading] = useState(true);
  const [postingToInstagramStories, setPostingToInstagramStories] = useState(null);
  const [zoomed, setZoomed] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [currentPark, setCurrentPark] = useState(null);
  const [expandedParks, setExpandedParks] = useState({});
  const [currentImageDimensions, setCurrentImageDimensions] = useState({ width: '100%', height: 'auto' });

  // Modal and caption state
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editableCaption, setEditableCaption] = useState('');
  const [selectedPost, setSelectedPost] = useState(null);


  useEffect(() => {
    fetchContent();
  }, [filters]);

  const fetchContent = async () => {
    setLoading(true);
    try {
      let q;

      if (filters.date) {
        const startOfDay = new Date(filters.date);
        startOfDay.setHours(0, 0, 0, 0);

        const endOfDay = new Date(filters.date);
        endOfDay.setHours(23, 59, 59, 999);

        q = query(
          collection(db, "posts"),
          where('deletedTime', '==', null),
          where('postTime', '>=', startOfDay),
          where('postTime', '<=', endOfDay),
          orderBy('postTime', 'desc')
        );
      } else {
        q = query(
          collection(db, "posts"),
          where('deletedTime', '==', null),
          where('postTime', '>', new Date(Date.now() - 1 * 24 * 60 * 60 * 1000)), // Default to last 24 hours if no date selected
          orderBy('postTime', 'desc')
        );
      }

      const querySnapshot = await getDocs(q);
      const contentData = querySnapshot.docs.map((doc) => {
        const data = doc.data();
        const likesCount = data.likes ? data.likes.length : 0;
        const commentsCount = data.comments ? data.comments.length : 0;
        const postTime = data.postTime.toDate();
        const currentTime = new Date();
        const timeDiffInSeconds = Math.floor((currentTime - postTime) / 1000);
        const timeDiffInMinutes = Math.floor(timeDiffInSeconds / 60);
        const timeDiffInHours = Math.floor(timeDiffInMinutes / 60);

        const timeAgo = timeDiffInMinutes < 60
          ? `${timeDiffInMinutes} minutes ago`
          : `${timeDiffInHours} hours ago`;

        let likesToTimeRatio = 0;
        if (likesCount > 1 && timeDiffInSeconds > 0) {
          likesToTimeRatio = likesCount / timeDiffInSeconds;
        }

        return {
          id: doc.id,
          ...data,
          likesCount,
          commentsCount,
          likesToTimeRatio,
          timeAgo,
        };
      });

      // Apply filters
      const filteredContent = contentData.filter(item => {
        if (filters.showVideos && !item.postVideo) return false;
        if (filters.showVerticalVideos && (item.imageHeight <= item.imageWidth)) return false;
        if (item.likesCount < filters.minLikes) return false;
        if (item.commentsCount < filters.minComments) return false;
        if (item.contentType === 'text') return false;
        return true;
      });

      // Sort by likes to time ratio in descending order
      const sortedContent = filteredContent.sort((a, b) => b.likesToTimeRatio - a.likesToTimeRatio);

      setContent(sortedContent);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching content: ", error);
      setLoading(false);
    }
  };

  const groupedContent = content.reduce((acc, item) => {
    if (!acc[item.park]) acc[item.park] = [];
    acc[item.park].push(item);
    return acc;
  }, {});

  const handleZoom = (park, index) => {
    setCurrentPark(park);
    setCurrentIndex(index);
    updateCurrentImageDimensions(park, index);
    setZoomed(true);
  };

  const updateCurrentImageDimensions = (park, index) => {
    const currentItem = groupedContent[park][index];
    setCurrentImageDimensions({
      width: currentItem.imageWidth,
      height: currentItem.imageHeight,
    });
  };

  const handleNext = () => {
    const parkContent = groupedContent[currentPark];
    const newIndex = (currentIndex + 1) % parkContent.length;
    setCurrentIndex(newIndex);
    updateCurrentImageDimensions(currentPark, newIndex);
  };

  const handlePrev = () => {
    const parkContent = groupedContent[currentPark];
    const newIndex = (currentIndex - 1 + parkContent.length) % parkContent.length;
    setCurrentIndex(newIndex);
    updateCurrentImageDimensions(currentPark, newIndex);
  };

  const handleKeyDown = useCallback((event) => {
    if (event.key === 'ArrowRight') {
      handleNext();
    } else if (event.key === 'ArrowLeft') {
      handlePrev();
    } else if (event.key === 'Escape') {
      setZoomed(false);
    }
  }, [handleNext, handlePrev]);

  useEffect(() => {
    if (zoomed) {
      window.addEventListener('keydown', handleKeyDown);
    } else {
      window.removeEventListener('keydown', handleKeyDown);
    }
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [zoomed, handleKeyDown]);

  const togglePark = (park) => {
    setExpandedParks({
      ...expandedParks,
      [park]: !expandedParks[park]
    });
  };

  const handleOpenInNewTab = () => {
    window.open(`/tings/${groupedContent[currentPark][currentIndex].id}`, '_blank');
  };
  const handlePostToInstagram = (post) => {
    setSelectedPost(post);
    const defaultCaption = generateCaption(
      post.animal,
      post.roadname,
      post?.adminTingedForWho ?? post.userData?.username,
      post.number,
      post.tingTime
    ).replace(/%0A/g, '\n'); ;
    setEditableCaption(defaultCaption);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedPost(null);
  };

  const handlePostConfirm = async () => {

    const formattedCaption = editableCaption.replace(/\n/g, '%0A');
    setPostingToInstagramStories(true);
    postToInstagramStories(
      selectedPost.postVideo,
      'video',
      selectedPost.animal,
      selectedPost.roadname,
      selectedPost?.adminTingedForWho ?? selectedPost.userData?.username,
      selectedPost.number,
      selectedPost.tingTime,
      formattedCaption // Pass the edited caption to the function
    );
    setIsModalOpen(false);
  };

  return (
    <div>
      <HighlightedParks parks={groupedContent} onParkClick={togglePark} activePark={currentPark} />
      {loading ? (
        <div>Loading...</div>
      ) : (
        Object.keys(groupedContent).map(park => (
          <div key={park}>
            <h2 onClick={() => togglePark(park)}>{park}</h2>
            {expandedParks[park] !== false && (
              <div className='grid'>
                {groupedContent[park].slice(0, 20).map((item, index) => (
                  <ContentItem key={item.id} item={item} onZoom={() => handleZoom(park, index)} onPostToInstagram={() => handlePostToInstagram(item, item.caption)} postingToInstagramStories={postingToInstagramStories} setPostingToInstagramStories={setPostingToInstagramStories} />
                ))}
              </div>
            )}
          </div>
        ))
      )}
      {zoomed && currentIndex >= 0 && currentPark && (
        <div className="image-enlarger-overlay">
          <Image
            style={{
              width: currentImageDimensions.width,
              height: currentImageDimensions.height,
              maxWidth: '90%',
              maxHeight: '90%',
              borderRadius: '5px',
            }}
            zoomed={zoomed}
            src={groupedContent[currentPark][currentIndex].postImg}
            onClick={() => setZoomed(false)}
            onRequestClose={() => setZoomed(false)}
          />
          <div className="overlay-buttons">
            <button className="prev-button" onClick={handlePrev}>Previous</button>
            <button className="next-button" onClick={handleNext}>Next</button>
            <button className="open-new-tab-button prev-button" onClick={handleOpenInNewTab}>Open in New Tab</button>
          </div>
        </div>
      )}

      {/* Modal for editing caption */}
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: '10px'
          }}
        >
          <TextField
            fullWidth
            multiline
            rows={4}
            label="Edit Caption"
            value={editableCaption}
            onChange={(e) => setEditableCaption(e.target.value)}
          />
          <Button variant="contained" onClick={handlePostConfirm} sx={{ mt: 2 }}>
            Post to Instagram
          </Button>
          <Button variant="outlined" onClick={handleCloseModal} sx={{ mt: 2 }}>
            Cancel
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default ContentList;
