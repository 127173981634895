import React, { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import useTestStore from '../../../stores/testStore';

import moment from 'moment';


// storage
import { storage } from '../../../firebase';
import { ref, uploadBytesResumable, getDownloadURL, uploadString } from "firebase/storage"

import Resizer from "react-image-file-resizer";

export default function ThumbnailVariations() {
    const newTestDoc = useTestStore(state => state.newTestDoc);
    const setNewTestDoc = useTestStore(set => set.setNewTestDoc);

    const [percentage1, setPercentage1] = useState(0);
    const [percentage2, setPercentage2] = useState(0);
    const [percentage3, setPercentage3] = useState(0);

    const [file1, setFile1] = useState("");
    const [file2, setFile2] = useState("");
    const [file3, setFile3] = useState("");

    const [url1, setUrl1] = useState("");
    const [url2, setUrl2] = useState("");
    const [url3, setUrl3] = useState("");

    const handleUpload = async (event, file, index) => {
        // Prevent default behavior (Prevent page from refreshing)
        event.preventDefault();
        console.log('handleUpload', file, index);
        if (!file) {
            alert("Please choose a file first!")
        }
        // Get today's month
        // const reference = ref(storage, "tests/");

        // Resize image
        // const resizedImage = await resizeImage(file);
        // console.log('resizedImage', resizedImage);

        const storageRef = ref(storage, `/postTests/${moment().format('YYYY')}/${moment().format('MMMM')}/${newTestDoc.testTitle}/${index}`)

        // const uploadTask = await uploadString(storageRef, resizedImage, "data_url");


        const uploadTask = uploadBytesResumable(storageRef, file);
        
        uploadTask.on("state_changed",
            (snapshot) => {
                const progress =
                    Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                console.log("Upload is " + progress + "% done");
                if (index === "0") {
                    setPercentage1(progress);
                }
                else if (index === "1") {
                    setPercentage2(progress);
                }
                else if (index === "2") {
                    setPercentage3(progress);
                }
            },
            (error) => {
                alert(error);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    if (index === "0") {
                        setUrl1(downloadURL)
                        const currentThumbnails = newTestDoc.thumbnails;
                        currentThumbnails[0] = downloadURL;
                        setNewTestDoc({ ...newTestDoc, thumbnails: currentThumbnails });
                    }
                    else if (index === "1") {
                        setUrl2(downloadURL)
                        const currentThumbnails = newTestDoc.thumbnails;
                        currentThumbnails[1] = downloadURL;
                        setNewTestDoc({ ...newTestDoc, thumbnails: currentThumbnails });
                    }
                    else if (index === "2") {
                        setUrl3(downloadURL)
                        const currentThumbnails = newTestDoc.thumbnails;
                        currentThumbnails[2] = downloadURL;
                        setNewTestDoc({ ...newTestDoc, thumbnails: currentThumbnails });
                    }

                });
            }
        );
    }

    return (
        <>
            {/* <div style={{ marginTop: '20px' }} /> */}
            <div >
                <br />
                Choose 3 different thumbnails to test:
            </div>
            {/* line break */}
            {/* <div style={{ marginTop: '20px' }} /> */}

            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                }}
            //   noValidate
            //   autoComplete="off"
            >

                <div style={{ display: 'flex' }}>

                    <div>
                        <input type="file" accept="image/*"
                            onChange={(event) => setFile1(event.target.files[0])} />
                        <button style={{ borderWidth: '0px', backgroundColor: 'white', color: 'blue', textDecoration: 'underline', borderColor: 'black' }}
                            onClick={(event) => handleUpload(event, file1, "0")}>Upload</button>
                        {url1 && <img src={url1} alt="uploaded" />}
                        {/* Percentage */}

                        <p>Percentage:{percentage1}</p>

                    </div>
                    <div>
                        <input type="file" accept="image/*"
                            onChange={(event) => setFile2(event.target.files[0])} />
                        <button style={{ borderWidth: '0px', backgroundColor: 'white', color: 'blue', textDecoration: 'underline', borderColor: 'black' }}
                            onClick={(event) => handleUpload(event, file2, "1")}>Upload</button>
                        {url2 && <img src={url2} alt="uploaded" />}
                        <p>Percentage:{percentage2}</p>

                    </div>
                    <div>
                        <input type="file" accept="image/*"
                            onChange={(event) => setFile3(event.target.files[0])} />
                        <button style={{ borderWidth: '0px', backgroundColor: 'white', color: 'blue', textDecoration: 'underline', borderColor: 'black' }}
                            onClick={(event) => handleUpload(event, file3, "2")}>Upload</button>
                        {url3 && <img src={url3} alt="uploaded" />}
                        <p>Percentage:{percentage3}</p>

                    </div>
                </div>


            </Box >
            {/* </div > */}

        </>
    );
}
