import React, { useEffect } from 'react';
import './postTest.css';

// Components
import EditTest from '../../../../components/Test/EditTest';

import useTestStore from '../../../../stores/testStore';
import { getTest } from '../../../../utils/firebase/getTest';

export default function Test() {
    const testDoc = useTestStore(state => state.testDoc);
    const setTestDoc = useTestStore(set => set.setTestDoc);
    const [loading, setLoading] = React.useState(true);
    const testId = window.location.pathname.split('/')[2]


    useEffect(() => {
        assignUser()
    }, [])

    const assignUser = async (userId) => {
        const testDocData = await getTest(window.location.pathname.split('/')[2]);
        setTestDoc(testDocData.testDocReceived);
        setLoading(testDocData.loaded)

    }

    return (
        <>
            <div className='user__container'>
                <div className='user__heading'>
                    Edit
                </div>
                {/* save changes button */}


                {!loading && <>
                    <EditTest testDoc={testDoc} testId={testId} />
                </>}

               

            </div>
        </>

    );
}