import { collection, query, orderBy, updateDoc, where, doc, serverTimestamp, deleteDoc } from "firebase/firestore";
import { db } from '../../firebase';
// import { useHistory } from "react-router-dom";


export const handleDeleteTest = async (testId) => {

    console.log('testId', testId);

    const q = doc(db, "postTests", testId);

    try {

        // Delete test from db
        let Qsnapshot = await deleteDoc(q).then(() => {
            // setUpdatedProfile(true)
            console.log('Ting deleted!');
            alert(
                'Test deleted!',
            );
        })

        // TODO: Delete photos and videos from storage
    }
    catch (error) {
        alert(error);
    }

}