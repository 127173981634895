
import { collection, query, orderBy, getDocs, where, limit } from "firebase/firestore";
import { db } from '../../firebase';

export const fetchTodaysTings = async () => {
    console.log('Fetching new users');
    let list = [];

    try {
        const q = query(collection(
            db, "posts"),
            where('deletedTime', '==', null),
            where('postTime', '>', new Date(Date.now() - (1) * 24 * 60 * 60 * 1000)),
            orderBy('postTime', 'desc'),
            limit(100));

        const querySnapshot = await getDocs(q);

        querySnapshot.forEach((doc) => {
            console.log('doc', doc.id);
            const {
                ...ting
            } = doc.data();
            list.push({
                ...ting,
                id: doc.id,
            });
        })
        console.log('list', list);
        return {
            list, loaded: false
        }
    } catch (e) {
        console.log(e);
    }

};