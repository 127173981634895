import React from "react";
// AuthStack

import Login from "./screens/authStack/Login";
import Register from "./screens/authStack/Register";

// AppStack
import Dashboard from "./screens/appStack/Dashboard";
import WapParks from "./screens/appStack/wap/wapParks";
import Settings from "./screens/appStack/Settings";
import Ting from "./screens/appStack/tings/ShowTing/Ting";
import Tings from "./screens/appStack/tings/TingList/Tings";
import User from "./screens/appStack/Users/ShowUser/User.jsx";
import Users from "./screens/appStack/Users/UserList/Users.jsx";
import ContentPage from "./screens/appStack/content/ContentPage/ContentPage.js";

import { Route, Routes } from "react-router-dom";

import ProtectedRoute from "./navigation/ProtectedRoutes";
import UnprotectedRoute from "./navigation/UnprotectedRoutes";
import useAuthStore from "./stores/authStore";

import './App.css';
import NewPostTest from "./screens/appStack/postTests/CreatePostTest/NewTestScreen.jsx";
import Test from "./screens/appStack/postTests/ShowPostTest/PostTest.jsx";
import PostTests from "./screens/appStack/postTests/PostTestList/PostTests.jsx";

import ReactGA from 'react-ga';

import SiteHeader from "./components/allPages/SiteHeader.js";

function App() {
  ReactGA.initialize('UA-23352698-10');
  ReactGA.pageview(window.location.pathname + window.location.search);

  const user = useAuthStore(state => state.user);

  return (
    <div className="app" >
      {/* {user ? <MenuComponent /> : null} */}
      <SiteHeader mode={'light'} />

      {/* <h1 className="app__heading">
        Latest Sightings Admin Panel
      </h1> */}

      <Routes>
        {/* AUTHstack */}
        <Route path="/login" element={<UnprotectedRoute><Login /></UnprotectedRoute>} />
        <Route path="/register" element={<UnprotectedRoute><Register /></UnprotectedRoute>} />

        {/* APPstack */}
        {/* Home page */}
        <Route path="/" element={
          <ProtectedRoute><Dashboard /></ProtectedRoute>} />

        {/* Users List */}
        <Route path="/users" element={
          <ProtectedRoute><Users /></ProtectedRoute>} />

        {/* Tings List */}
        <Route path="/tings" element={
          <ProtectedRoute><Tings /></ProtectedRoute>} />

        {/* Parks list */}
        <Route path="/whatsapp-parks" element={
          <ProtectedRoute><WapParks /></ProtectedRoute>} />
 
        {/* Settings page */}
        <Route path="/settings" element={
          <ProtectedRoute><Settings /></ProtectedRoute>} />

        {/* Individual user */}
        <Route path="/users/:userId" element={
          <ProtectedRoute><User /></ProtectedRoute>} />

        {/* Individual ting */}
        <Route path="/tings/:tingId" element={
          <ProtectedRoute><Ting /></ProtectedRoute>} />

        {/* PostTests list */}
        <Route path="/posttests" element={
          <ProtectedRoute><PostTests /></ProtectedRoute>} />

        {/* Individual test */}
        <Route path="/test/:testId" element={
          <ProtectedRoute><Test /></ProtectedRoute>} />

        {/* New test */}
        <Route path="/newposttest/" element={
          <ProtectedRoute><NewPostTest /></ProtectedRoute>} />

        {/* Content list */}
        <Route path="/content" element={
          <ProtectedRoute><ContentPage /></ProtectedRoute>} />
          
      </Routes>

    </div>
  );
}

export default App;
