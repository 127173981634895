import { collection, query, orderBy, updateDoc, where, doc, serverTimestamp } from "firebase/firestore";
import { db } from '../../firebase';

export const handleUpdate = async (userDoc) => {

    const q = doc(db, "users", userDoc.uid);

    try {
        let Qsnapshot = await updateDoc(q, {
            ...userDoc, updatedTime: serverTimestamp(),
        }).then(() => {
            // setUpdatedProfile(true)
            console.log('User Updated!');
            alert(
                'Profile Updated!',
            );
        })
    }
    catch (error) {
        alert(error);
    }

}