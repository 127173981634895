import { doc, serverTimestamp, updateDoc } from "firebase/firestore";
import { db } from '../../firebase';

export const handleTestUpdate = async (testDoc, testId) => {
    const q = doc(db, "postTests", testId);

    try {
        // remove id from tingDoc  

        let Qsnapshot = await updateDoc(q, {
            ...testDoc, updatedTime: serverTimestamp(),
        }).then(() => {
            // setUpdatedProfile(true)
            console.log('Ting Updated!');
            alert(
                'Test Updated!',
            );
        })
    }
    catch (error) {
        alert(error);
    }

}