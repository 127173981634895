import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useAuthStore from '../../stores/authStore';
import useUserProfileStore from '../../stores/userProfileStore';
import { getUser } from '../../utils/firebase/getUser';
import { auth } from '../../firebase';

// CSS
import '../../css/AuthStack.css';
const Login = () => {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const signIn = useAuthStore(state => state.signIn);
  const logout = useAuthStore(state => state.logout);
  const [buttonHovered, setButtonHovered] = useState(false);
  const admin = useUserProfileStore(state => state.admin);

  return (
    <div className='auth__container'>
      <div>
        <h1 className='auth__heading'>Login to your account</h1>
        <p className='auth__byline'>
          Don't have an account yet?{' '}
          <Link to='/register' className='underline'>
            Register.
          </Link>
        </p>
      </div>

      <form onSubmit={async (e) => {
        const success = await signIn(e, email, password);
        if (success) {
          console.log('success');
          console.log('admin: ', admin);
          if (admin) {
            navigate('/')
          }
          else {
            // Alert
            alert('You are not an admin. Please login with an admin account.');
            logout();
            navigate('/login')
          }
        }
      }}>

        <div className='auth__formDiv'>
          <label className='auth__formHeading'>Email Address</label>
          <input onChange={(e) => setEmail(e.target.value)} className='auth__formInput' type='email' />
        </div>
        <div className='auth__formDiv'>
          <label className='auth__formHeading'>Password</label>
          <input onChange={(e) => setPassword(e.target.value)} className='auth__formInput' type='password' />
        </div>
        <button
          onMouseEnter={() => setButtonHovered(true)}
          onMouseLeave={() => setButtonHovered(false)}
          className={buttonHovered ? 'auth__buttonHovered' : 'auth__buttonNotHovered'}>
          Login
        </button>
      </form>
    </div>
  );
};

export default Login;
