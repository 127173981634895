import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import useUserProfileStore from '../../stores/userProfileStore';

export default function UserBioDetails() {
    const userDoc = useUserProfileStore(state => state.userDoc);
    const setUserDoc = useUserProfileStore(state => state.setUserDoc);

    const handleChange = (event) => {
        // Change value in setUserDoc based on what is typed
        setUserDoc({
            ...userDoc,
            [event.target.id]: event.target.value
        });
    };



    return (
        <>
            <div style={{ marginTop: '20px' }} />
            <div >
                User bio details
            </div>
            {/* line break */}
            <div style={{ marginTop: '20px' }} />

            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                }}
            //   noValidate
            //   autoComplete="off"
            >
                <div>
                    <TextField
                        id="about"
                        label="Bio"
                        value={userDoc.about ?? ''}
                        multiline
                        maxRows={4}
                        onChange={handleChange}
                    />
                    <TextField
                        id="country"
                        label="Country"
                        value={userDoc.country ?? ''}
                        onChange={handleChange}
                    />
                    <TextField
                        id="address"
                        label="Address"
                        value={userDoc.address ?? ''}
                        //   Width
                        style={{ width: '400px' }}
                        onChange={handleChange}

                        // Make non-editable
                        InputProps={{
                            readOnly: true,
                        }}

                    />
                </div>
                <div>
                    <TextField
                        id="tings"
                        label="Number of tings"
                        value={userDoc.tings ?? ''}
                        onChange={handleChange}
                        variant='filled'
                    />
                </div>
                <div>
                    <TextField
                        id="instagramHandle"
                        label="Instagram handle"
                        value={userDoc.instagramHandle ?? ''}
                        onChange={handleChange}
                    />
                    <TextField
                        id="twitterHandle"
                        label="Twitter handle"
                        value={userDoc.twitterHandle ?? ''}
                        onChange={handleChange}
                    />
                </div>
            </Box>
        </>
    );
}