import { collection, query, orderBy, updateDoc, where, doc, serverTimestamp } from "firebase/firestore";
import { db } from '../../firebase';

export const handleTingUpdate = async (tingDoc, tingId) => {
    const q = doc(db, "posts", tingId);

    try {
        // remove id from tingDoc  

        let Qsnapshot = await updateDoc(q, {
            ...tingDoc, updatedTime: serverTimestamp(),
        }).then(() => {
            // setUpdatedProfile(true)
            console.log('Ting Updated!');
            alert(
                'Ting Updated!',
            );
        })
    }
    catch (error) {
        alert(error);
    }

}