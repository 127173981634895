import { db } from '../../firebase';
import { collection, query, orderBy, getDoc, where, limit, doc } from "firebase/firestore";

export const getTest = async (id) => {
    console.log('Fetching Test');

    try {
        const q = doc(db, "postTests", id);

        const querySnapshot = await getDoc(q);

        let testDocReceived = querySnapshot.data()
        // Get doc ID
        testDocReceived = {
            ...testDocReceived,
            postId: querySnapshot.id
        }

        return {
            testDocReceived, loaded: false
        }
    } catch (e) {
        console.log(e);
    }

};