import * as React from 'react';

// Components
import UserEssentialDetails from './UserEssentialDetails';
import UserBioDetails from './UserBioDetails';
import UserContributorDetails from './UserContributorDetails';
import UserExtraDetails from './UserExtraDetails';

export default function EditUser() {

    return (
        <>
            <UserEssentialDetails />
            <UserBioDetails />
            <UserContributorDetails />
            <UserExtraDetails />
        </>
    );
}