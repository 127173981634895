import create from "zustand";

const useUserProfileStore = create((set) => ({
    userDoc: {},
    // setUserDoc: (userDoc) => set((state) => ({ ...state, userDoc })),
    setUserDoc: (userDoc) => set((state) => ({ userDoc })),
    admin: {},
    setAdmin: (admin) => set((state) => ({ admin })),
}));

export default useUserProfileStore;
