import moment from 'moment';
import { auth, storage } from '../../firebase'

export const generateChildPath = (tingObject, uri) => {
    console.log('Ting object:', tingObject);
console.log('Uri:', uri);    
    const base = `tings/${tingObject?.park.replace(/\s/g, '-')}/${moment().format('YYYY/MM/DD')}/${tingObject?.animal}`

    return `${base}/${tingObject.userId}-${Math.random().toString(36).substring(2)}-${uri.name.substring(uri.name.lastIndexOf('/') + 1)}`;
};

const uploadFile = async (uri, childPath, setTransferred) => {
    const reference = storage().ref(childPath);
    const task = reference.putFile(uri);
    setTransferred(0);
    task.on('state_changed', (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setTransferred(progress);
    }, (error) => {
        console.error('Upload error:', error);
    });

    try {
        await task;
        const downloadURL = await reference.getDownloadURL();
        return downloadURL;
    } catch (error) {
        console.error('Failed to upload file:', error);
        return null;
    }
};

export const uploadTingImage = async (uri, setTransferred, tingDoc) => {
    const childPath = generateChildPath(tingDoc, uri);
    console.log('Uploading to:', childPath);
    return await uploadFile(uri, childPath, setTransferred);
};
