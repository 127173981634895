import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import useTestStore from '../../stores/testStore';

export default function TingSocialDetails() {
    const testDoc = useTestStore(state => state.testDoc);
    const setTestDoc = useTestStore(state => state.setTestDoc);


    const handleChange = (event) => {
        // Change value in setUserDoc based on what is typed
        setTestDoc({
            ...testDoc,
            [event.target.id]: event.target.value
        });
    };



    return (
        <>
            <div style={{ marginTop: '20px' }} />
            <div >
                Ting Social Details
            </div>
            <div style={{ marginTop: '20px' }} />

            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                }}
            //   noValidate
            //   autoComplete="off"
            >
                <div>
                    <TextField
                        id="aveCTR"
                        label="Average CTR"
                        value={((testDoc.totalViews.length / testDoc.totalLoads.length).toFixed(2) * 100).toString().substring(0, 5) + "%"}
                        onChange={handleChange}
                        // disabled
                        variant="filled"
                        InputProps={{
                            readOnly: true,
                        }}
                    />

                    <TextField
                        id="aveWatchtime"
                        label="Average Watchtime"
                        value={(testDoc.totalWatchtime / testDoc.totalViews.length).toFixed(2) + "s"}
                        onChange={handleChange}
                        // disabled
                        variant="filled"
                        InputProps={{
                            readOnly: true,
                        }}
                    />

                </div>

                <div>
                    <TextField
                        id="totalViews"
                        label="Total Views"
                        value={testDoc.totalViews ? testDoc.totalViews.length : 0}
                        onChange={handleChange}
                        // disabled
                        variant="filled"
                        InputProps={{
                            readOnly: true,
                        }}
                    />

                    <TextField
                        id="totalLoads"
                        label="Total Loads"
                        value={testDoc.totalLoads ? testDoc.totalLoads.length : 0}
                        onChange={handleChange}
                        // disabled
                        variant="filled"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <TextField
                        id="uniqueLoads"
                        label="Unique Loads"
                        value={testDoc.uniqueLoads ? testDoc.uniqueLoads.length : 0}
                        onChange={handleChange}
                        // disabled
                        variant="filled"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <TextField
                        id="totalWatchtime"
                        label="Total Watchtime"
                        value={testDoc.totalWatchtime ?? 0}
                        onChange={handleChange}
                        // disabled
                        variant="filled"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <TextField
                        id="likes"
                        label="Likes"
                        value={testDoc.likes ? testDoc.likes.length : 0}
                        onChange={handleChange}
                        // disabled
                        variant="filled"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <TextField
                        id="comments"
                        label="Comments"
                        value={testDoc.comments ? testDoc.comments.length : 0}
                        onChange={handleChange}
                        // disabled
                        variant="filled"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </div>
                <div>
                    <TextField
                        id="tingedBy"
                        label="Tinged by"
                        value={testDoc.tingedBy ?? ''}
                        style={{ width: '370px' }}
                        onChange={handleChange}
                    />
                </div>
            </Box>
        </>
    );
}