import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import useTestStore from '../../stores/testStore';

import { handleDeleteTest } from '../../utils/firebase/deleteTest';
import { handleTestUpdate } from '../../utils/firebase/updateTest';

import { useNavigate } from "react-router-dom"

export default function TestDelete({ testDoc, testId }) {
    const navigate = useNavigate()

    // Get testId from testDoc

    const deleteTest = async () => {
        console.log('deleteTest');
        // Move to home page
        await handleDeleteTest(testId);
        navigate('/posttests');
    };

    return (
        <>
            {/* <div style={{ marginTop: '20px', direction: 'flex', flexDirection: 'row',  }} > */}
                <div >
                    Save Test:
                </div>
                <Button
                    onClick={() => {
                        handleTestUpdate(testDoc, testId)
                    }}
                    variant="contained">Save Changes</Button>


                {/* Delete button */}
                <div style={{ marginTop: '20px' }} />
                <div >
                    Delete Test
                </div>
                {/* line break */}
                <Button
                    onClick={() => {
                        if (window.confirm('Are you sure you wish to delete this item?'));
                        deleteTest()
                    }}
                    variant="outlined">Delete</Button>
                <div style={{ marginTop: '20px' }} />
            {/* </div> */}
        </>
    );
}