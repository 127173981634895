import React, { useState, useEffect } from 'react';
import '../../Users/UserList/Users.css';

import { Button } from '@mui/material';

// Components
import TestEssentialDetails from '../../../../components/Test/CreateTest/TestEssentialDetails';
import TitleVariations from '../../../../components/Test/CreateTest/TitleVariations';
import ThumbnailVariations from '../../../../components/Test/CreateTest/ThumbnailVariations';
import UploadVideo from '../../../../components/Test/CreateTest/UploadVideo';

// Get test
import { fetchPostTests } from '../../../../utils/firebase/fetchPostTests';
import { createNewPostTest } from '../../../../utils/firebase/createNewPostTest';

// Zustand
import useTestStore from '../../../../stores/testStore';

const NewPostTest = () => {
    const [testsDB, setTestsDB] = useState([]);
    const [loading, setLoading] = useState(true);

    const newTestDoc = useTestStore(state => state.newTestDoc);
    const setNewTestDoc = useTestStore(set => set.setNewTestDoc);
    const restoreNewTestDoc = useTestStore(set => set.restoreNewTestDoc);

    return (
        <div className='users__container'>
            <h1 className='users__heading'>
                Create a New Post Test
            </h1>

            <TestEssentialDetails />
            <TitleVariations />
            <ThumbnailVariations />
            <UploadVideo />


            <Button className='user__button'
                onClick={async () => {
                    await createNewPostTest(newTestDoc)
                    restoreNewTestDoc()
                }}
                // filled
                variant='contained'
            >Create Test</Button>

        </div >
    );
};

export default NewPostTest;
