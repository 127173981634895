import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuthStore from '../../stores/authStore';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { collection, query, orderBy, getDocs, where, limit } from "firebase/firestore";
import './Settings.css';
import * as XLSX from 'xlsx';
import { db } from '../../firebase';
import * as Excel from "exceljs";
import { saveAs } from "file-saver";

const Settings = () => {
  const [accounts, setAccounts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const user = useAuthStore(state => state.user);
  const logout = useAuthStore(state => state.logout);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {

      let list = [];

      const q = query(
        collection(db, 'users'),
        where('bankAccountNumber', '>', ''),
      );

      const querySnapshot = await getDocs(q);

      querySnapshot.forEach(doc => {
        const { ...test } = doc.data();
        list.push({
          ...test,
          id: doc.id,
        });
      });

      setAccounts(list);
      setIsLoading(false);
    };

    fetchData();
  }, []);

  let workbook = new Excel.Workbook()
  let worksheet = workbook.addWorksheet('your export sheet name')
  worksheet.columns = [

    { header: 'First Name', key: 'firstName' },
    { header: 'Last Name', key: 'lastName' },
    { header: 'email', key: 'email' },
    { header: 'Bank Name', key: 'bankAccountName' },
    { header: 'Account Number', key: 'bankAccountNumber' },
    { header: 'Branch Code', key: 'bankBranchCode' },
    { header: 'Paypal', key: 'paypalEmail' },
    { header: 'user Id', key: 'uid' },

  ]
  worksheet.getRow(1).font = { bold: true }

  const getData = async () => {
    let list = [];

    const q = query(
      collection(db, 'users'),
      where('bankAccountNumber', '>', ''),
    );

    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc, index) => {
      const rowIndex = index + 2
      let e = doc.data()

      // add to list 
      list.push({
        ...e,
        id: doc.id,
      });
    });

    const q2 = query(
      collection(db, 'users'),
      where('paypalEmail', '>', ''),
    );

    const querySnapshot2 = await getDocs(q2);

    querySnapshot2.forEach((doc, index) => {
      const rowIndex = index + 2
      let e = doc.data()

      // add to list 
      list.push({
        ...e,
        id: doc.id,
      });
    });

    // Remove duplicates from workdbook
    const unique = [...new Set(list.map(item => item.id))];
    const uniqueList = unique.map(id => {
      return list.find(item => item.id === id);
    });

    // Add to workbook
    uniqueList.forEach((item, index) => {
      const rowIndex = index + 2
      worksheet.addRow({
        ...item,
      })
    });

    // workbook.xlsx.writeBuffer('yourexportfilename.xlsx')
    const buffer = await workbook.xlsx.writeBuffer();
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const fileExtension = '.xlsx';

    const blob = new Blob([buffer], { type: fileType });

    saveAs(blob, 'fileName' + fileExtension);

  }

  return (
    <div className='account__container'>
      <h1 className='account__heading'>Settings</h1>

      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <>
          <p>User email: {user && user.email}</p>
          <button className='button' onClick={getData}>Export bank accounts to Excel</button>
        </>
      )}
    </div>
  );
};

export default Settings;
