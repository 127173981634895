//import useState hook to create menu collapse state
import React, { useState } from "react";

//import react pro sidebar components
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";

//import icons from react icons
import { FaList } from "react-icons/fa";
// IoIosPeople
import { BsFillPeopleFill, BsFillPinAngleFill } from 'react-icons/bs'
import { FiHome, FiLogOut, FiArrowLeftCircle, FiArrowRightCircle } from "react-icons/fi";
import { BiCog } from "react-icons/bi";


//import sidebar css from react-pro-sidebar module and our custom css 


import useAuthStore from "../../stores/authStore";
import { useNavigate } from 'react-router-dom';

const Search = () => {

  return (
      <div>Search</div>
  )
};

export default Search;