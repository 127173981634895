import React, { useEffect, useState } from 'react';
import './User.css';

// Components
import EditUser from '../../../../components/User/EditUser';
import ProfilePhoto from '../../../../components/User/ProfilePhoto';

import { getUser } from '../../../../utils/firebase/getUser';
import { handleUpdate } from '../../../../utils/firebase/updateUser';
import useUserProfileStore from '../../../../stores/userProfileStore';

export default function User() {
    const userDoc = useUserProfileStore(state => state.userDoc);
    const setUserDoc = useUserProfileStore(set => set.setUserDoc);
    const [loading, setLoading] = React.useState(true);


    useEffect(() => {
        assignUser()
    }, [])

    const assignUser = async (userId) => {
        const userDocData = await getUser(window.location.pathname.split('/')[2]);
        setUserDoc(userDocData.userDocData);
        console.log('userDocdat', userDocData.userDocData);
        setLoading(userDocData.loaded)

    }

    return (
        <>
            <div className='user__container'>
                <div className='user__heading'>
                    Edit
                </div>
                {/* save changes button */}
                <button className='user__button'
                    onClick={() => {
                        handleUpdate(userDoc)
                    }}
                >Save Changes</button>


                {!loading && <>

                    <ProfilePhoto />
                    <EditUser />

                </>}
            </div>
        </>

    );
}