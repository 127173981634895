import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import useTestStore from '../../stores/testStore';
import { HomeRepairService } from '@mui/icons-material';

export default function TingContentDetails() {
    const testDoc = useTestStore(state => state.testDoc);
    const setTestDoc = useTestStore(state => state.setTestDoc);

    const handleChange = (event) => {
        // Change value in setUserDoc based on what is typed
        setTestDoc({
            ...testDoc,
            [event.target.id]: event.target.value
        });
    };



    return (
        <>
            <div style={{ marginTop: '20px' }} />
            <div >
                Ting Content Details
            </div>
            {/* line break */}
            <div style={{ marginTop: '20px' }} />

            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                }}
            //   noValidate
            //   autoComplete="off"
            >
                <div>
                    <TextField
                        id="testType"
                        label="Test Type"
                        value={testDoc.testType ?? ''}
                        onChange={handleChange}
                        // disabled
                        variant="filled"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    {testDoc.postImg && <a href={testDoc.postImg}>Download image</a>}
                    {/* Show video player */}
                    {testDoc.postVideo && <video src={testDoc.postVideo} controls 
                    style={{width: '50%', height: 'auto'}} />}   

                </div>
            </Box>
        </>
    );
}