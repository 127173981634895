import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import useTestStore from '../../stores/testStore';

import "./TestVariationDetails.css";

import React, { useState } from 'react';

import moment from 'moment';


// storage
import { storage } from '../../firebase';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"
import { doc, serverTimestamp, updateDoc } from "firebase/firestore";
import { db } from '../../firebase';

export default function TestVariationDetails() {
    const testDoc = useTestStore(state => state.testDoc);
    const setTestDoc = useTestStore(state => state.setTestDoc);

    const [editingIndex, setEditingIndex] = useState(null);

    const [percentage, setPercentage] = useState(0);
    const [file, setFile] = useState("");
    const [url1, setUrl1] = useState("");

    const handleChange = (event) => {
        console.log('event.target.id', event.target.id);
        const tempTitles = testDoc.titles;
        tempTitles[event.target.id] = event.target.value;
        setTestDoc({
            ...testDoc,
            titles: tempTitles
        });
    };


    const handleEdit = async (event, file, index) => {
        // Prevent default behavior (Prevent page from refreshing)
        event.preventDefault();
        console.log('handleEdit', file, index);
        if (!file) {
            alert("Please choose a file first!")
        }
        // Get today's month
        // const reference = ref(storage, "tests/");

        // Resize image
        // const resizedImage = await resizeImage(file);
        // console.log('resizedImage', resizedImage);

        const storageRef = ref(storage, `/postTests/${moment().format('YYYY')}/${moment().format('MMMM')}/${testDoc.testTitle}/${index}`)

        // const uploadTask = await uploadString(storageRef, resizedImage, "data_url");


        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on("state_changed",
            (snapshot) => {
                const progress =
                    Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                console.log("Upload is " + progress + "% done");
                setPercentage(progress);
            },
            (error) => {
                alert(error);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                    // Set to firestore database
                    console.log('downloadURL', downloadURL);
                    console.log('testDoc.testId', testDoc.postId);
                    const q = doc(db, "postTests", testDoc.postId);

                    // Generate new thumbnail array
                    const newThumbnailArray = testDoc.thumbnails;
                    newThumbnailArray[index] = downloadURL;

                    try {

                        let Qsnapshot = await updateDoc(q, {
                            updatedTime: serverTimestamp(),
                            // Make thumbnail array with index index equal to downloadURL, but keep the rest of the array the same
                            'thumbnails': newThumbnailArray
                        }).then(() => {
                            // setUpdatedProfile(true)
                            console.log('Test Edited!');
                            alert(
                                'Edit Saved!',
                            );
                            // Refresh page
                            window.location.reload();
                        })
                    }
                    catch (error) {
                        alert(error);
                    }

                });
            }
        );
    }


    return (
        <>
            <div style={{ marginTop: '20px' }} />
            <div >
                Test Variation Details
            </div>
            {/* line break */}
            <div style={{ marginTop: '20px' }} />

            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                }}
            //   noValidate
            //   autoComplete="off"
            >

                <div style={{ display: 'flex' }}>
                    <div>
                        {/* Repeat for how many titles there are */}
                        {testDoc.titles.map((title, index) => {
                            return (
                                <>
                                    <div className='titles' >
                                        <TextField
                                            style={{ width: '100%' }}
                                            id={`${index}`}
                                            label={`Title ${index}`}
                                            value={title ?? ''}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </>
                            )
                        })
                        }
                    </div>
                    <div>
                        {/* Repeat for how many titles there are */}
                        {testDoc.thumbnails.map((thumbnail, index) => {
                            return (
                                <>
                                    <div className='thumbnails'>

                                        {/* Show image */}
                                        <img
                                            src={thumbnail}
                                            alt="thumbnail"
                                            style={{ height: 100 }}
                                        />
                                        <div>
                                            {index}
                                        </div>

                                        {editingIndex === index && <Box component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' }, }}>

                                            <div style={{ display: 'flex' }}>
                                                <div>
                                                    <input type="file" accept="image/*"
                                                        onChange={(event) => setFile(event.target.files[0])} />
                                                    <button style={{ borderWidth: '0px', backgroundColor: 'white', color: 'blue', textDecoration: 'underline', borderColor: 'black' }}
                                                        onClick={(event) => handleEdit(event, file, index)}>Upload</button>
                                                    {url1 && <img src={url1} alt="uploaded" />}
                                                    <p>Percentage:{percentage}</p>
                                                </div>
                                            </div>


                                        </Box >}

                                        {/* Add a buttom to edit the thumbnail */}
                                        {editingIndex !== index && < div >
                                            <Button
                                                onClick={() => {
                                                    // Ask for file
                                                    setEditingIndex(index);
                                                    // handleEdit(index)
                                                }}
                                                variant="contained">Edit</Button>

                                        </div>}
                                    </div>
                                </>
                            )
                        })
                        }
                    </div>
                </div>
            </Box>
        </>
    );
}