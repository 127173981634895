import useTingStore from '../../stores/tingStore';
import React from 'react';
import { Button, TextField, Typography } from '@mui/material';
import { generateChildPath } from '../../utils/firebase/uploadPhoto';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { db, storage } from '../../firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';

export default function Media() {
    const tingDoc = useTingStore(state => state.tingDoc);
    const updateTingDoc = useTingStore(state => state.updateTingDoc);
    const [selectedPhoto, setSelectedPhoto] = React.useState(null);
    const [transferred, setTransferred] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [uploaded, setUploaded] = React.useState(false);

    const handleSingleFileChange = (e) => {
        console.log('Selected photo:', e.target.files[0]);
        setSelectedPhoto(e.target.files[0]);
    };

    const uploadFile = async (file, path, setProgress) => {
        return new Promise((resolve, reject) => {
            const fileRef = ref(storage, path);
            const uploadTask = uploadBytesResumable(fileRef, file);

            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const percent = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    setProgress(percent);
                },
                (err) => reject(err),
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((url) => resolve(url));
                }
            );
        });
    };


    const handleUpload = async () => {
        setLoading(true);
        try {
            console.log('Ting doc:', tingDoc);
            const tingRef = doc(db, 'posts', tingDoc.postId);
            console.log('Ting ref:', tingRef.path);
            const tingSnap = await getDoc(tingRef);
            console.log('Ting snap:', tingSnap.data());

            if (tingSnap.exists()) {
                const tingData = tingSnap.data();

                const photoChildPath = generateChildPath(tingData, selectedPhoto);
                console.log('Photo child path:', photoChildPath);

                const photoURL = selectedPhoto ? await uploadFile(selectedPhoto, photoChildPath, setTransferred) : tingData.postImg;


                await updateDoc(tingRef, {
                    postImg: photoURL,
                    contentType: 'image',
                    updatedTime: new Date(),
                    postVideo: null,
                });

                setUploaded(true);
                // Refresh the page
                window.location.reload();

            } else {
                console.error('No such document!');
            }
        } catch (error) {
            console.error('Error uploading photos:', error);
        }
        setLoading(false);
    };

    return (
        <>
            <div className='user__profile-photo'>
                {tingDoc.postImg && <img
                    style={{
                        width: tingDoc.imageWidth,
                        height: tingDoc.imageHeight,
                        maxHeight: '400px',
                        maxWidth: (400 * tingDoc.imageWidth) / tingDoc.imageHeight,
                    }}
                    src={tingDoc.postImg ? tingDoc.postImg : 'https://cdn1.iconfinder.com/data/icons/random-115/24/person-1024.png'}
                    alt='profile' />}
                {tingDoc.postVideo && (
                    <video
                        style={{
                            width: tingDoc.imageWidth,
                            height: tingDoc.imageHeight,
                            maxHeight: '400px',
                            maxWidth: (400 * tingDoc.imageWidth) / tingDoc.imageHeight,
                        }}
                        src={tingDoc.postVideo}
                        controls
                    />
                )}

                <Typography variant="h6">Change Photo</Typography>
                <TextField type="file" onChange={handleSingleFileChange} fullWidth />
                <Button
                    disabled={loading}
                    variant="contained"
                    color="primary"
                    onClick={handleUpload}>Upload</Button>
                <Typography variant="body2">{transferred}% uploaded</Typography>
            </div>
        </>
    );
}
