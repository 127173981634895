import React, { useRef, useEffect } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import moment from 'moment';

import useTingStore from '../../stores/tingStore';

export default function TingEssentialDetails() {
    const tingDoc = useTingStore(state => state.tingDoc);
    const setTingDoc = useTingStore(state => state.setTingDoc);
    const dateInputRef = useRef(null);

    const handleChange = (event) => {
        setTingDoc({
            ...tingDoc,
            [event.target.id]: event.target.value
        });
    };

    const handleDateChange = (event) => {
        const date = moment(event.target.value).toDate();
        setTingDoc({
            ...tingDoc,
            tingTime: date
        });
    };

    const formattedTime = tingDoc.tingTime
        ? moment(tingDoc.tingTime.seconds ? tingDoc.tingTime.seconds * 1000 : tingDoc.tingTime).format('YYYY-MM-DDTHH:mm')
        : '';

    useEffect(() => {
        if (dateInputRef.current) {
            dateInputRef.current.addEventListener('click', () => {
                dateInputRef.current.showPicker();
            });
        }
    }, []);

    return (
        <>
            <div style={{ marginTop: '20px' }} />
            <div>
                Ting Core Details
            </div>
            <div style={{ marginTop: '20px' }} />

            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                }}
            >
                <div>
                    <TextField
                        id="tingTime"
                        label="Time of Sighting"
                        type="datetime-local"
                        inputRef={dateInputRef}
                        value={formattedTime}
                        onChange={handleDateChange}
                    />
                    <TextField
                        id="park"
                        label="Park"
                        value={tingDoc.park ?? ''}
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <TextField
                        id="number"
                        label="How many"
                        style={{ width: '100px' }}
                        value={tingDoc.number ?? ''}
                        onChange={handleChange}
                    />
                    <TextField
                        id="animal"
                        label="Animal"
                        style={{ width: '150px' }}
                        value={tingDoc.animal ?? ''}
                        onChange={handleChange}
                    />
                    <TextField
                        id="action"
                        label="Action"
                        style={{ width: '150px' }}
                        value={tingDoc.action ?? ''}
                        onChange={handleChange}
                    />
                    <TextField
                        id="extraAnimal"
                        label="Extra Animal"
                        style={{ width: '150px' }}
                        value={tingDoc.extraAnimal ?? ''}
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <TextField
                        id="roadname"
                        label="Roadname"
                        value={tingDoc.roadname ?? ''}
                        onChange={handleChange}
                    />
                    <TextField
                        id="locationDescription"
                        label="Location description"
                        value={tingDoc.locationDescription ?? ''}
                        maxRows={4}
                        multiline
                        style={{ width: '500px' }}
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <TextField
                        id="visibility"
                        label="Vis"
                        value={tingDoc.visibility ?? ''}
                        style={{ width: '50px' }}
                        onChange={handleChange}
                    />
                    <TextField
                        id="traffic"
                        label="Cars"
                        value={tingDoc.traffic ?? ''}
                        style={{ width: '50px' }}
                        onChange={handleChange}
                    />
                    <TextField
                        id="tingDescription"
                        label="Ting description"
                        value={tingDoc.tingDescription ?? ''}
                        style={{ width: '600px' }}
                        onChange={handleChange}
                        maxRows={4}
                        multiline
                    />
                </div>
            </Box>
        </>
    );
}
