import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { GeoPoint } from 'firebase/firestore';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import * as React from 'react';
import { MapContainer, Marker, Polyline, TileLayer, useMapEvents } from 'react-leaflet';

import useTingStore from '../../stores/tingStore';

// Fix for marker icon not showing up
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
    iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
    shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
});

const LocationMarker = ({ setCoords }) => {
    useMapEvents({
        click(e) {
            setCoords(e.latlng);
        },
    });

    return null;
};

const callProcessLocation = async (latitude, longitude) => {
    const response = await fetch('https://us-central1-latestsightingsbackoffice.cloudfunctions.net/processLocation', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ latitude, longitude })
    });

    if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message);
    }

    return await response.json();
};

export default function TingLocationDetails() {
    const tingDoc = useTingStore(state => state.tingDoc);
    const setTingDoc = useTingStore(state => state.setTingDoc);
    const [coords, setCoords] = React.useState([{ lat: tingDoc.coordinates.latitude, lng: tingDoc.coordinates.longitude }]);
    const [distance, setDistance] = React.useState(null);
    const [roadName, setRoadName] = React.useState('');
    const [locationDescription, setLocationDescription] = React.useState('');
    const [processingLocation, setProcessingLocation] = React.useState(false);

    const handleCoordChange = (event) => {
        const newCoords = [...coords];
        if (event.target.id === 'lat') {
            newCoords[0] = { ...newCoords[0], lat: parseFloat(event.target.value) };
        } else {
            newCoords[0] = { ...newCoords[0], lng: parseFloat(event.target.value) };
        }
        setCoords(newCoords);
        setTingDoc({
            ...tingDoc,
            coordinates: new GeoPoint(newCoords[0].lat, newCoords[0].lng)
        });
    };


    const handleAddCoord = () => {
        if (coords.length < 2) {
            setCoords([...coords, { lat: 0, lng: 0 }]);
        }
    };

    const handleCoordUpdate = (index, coord) => {
        const newCoords = [...coords];
        newCoords[index] = coord;
        setCoords(newCoords);
        setTingDoc({
            ...tingDoc,
            coordinates: new GeoPoint(newCoords[0].lat, newCoords[0].lng)
        });
        fetchLocationDetails(coord, index);
    };

    const fetchLocationDetails = async (coord, index) => {
        try {
            setProcessingLocation(true);
            const result = await callProcessLocation(coord.lat, coord.lng);
            console.log('result:', result);

            setRoadName(result.roadname);
            setLocationDescription(result.locationDescription);
            setProcessingLocation(false);
        } catch (error) {
            console.error('Error fetching road name:', error);
        }
    };

    const appendToLocationDescription = () => {
        setTingDoc({
            ...tingDoc,
            locationDescription
        });
    };

    console.log('tingDoc:', tingDoc);
    return (
        <>
            <div style={{ marginTop: '20px' }} />
            <div>
                Ting Location Details
            </div>
            <div style={{ marginTop: '20px' }} />

            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                }}
            >
                <div>
                    <TextField
                        id="lat"
                        label="Latitude"
                        value={coords[0].lat}
                        onChange={handleCoordChange}
                    />
                    <TextField
                        id="lng"
                        label="Longitude"
                        value={coords[0].lng}
                        onChange={handleCoordChange}
                    />
                    <TextField
                        id="accuracy"
                        label="Map accuracy"
                        value={tingDoc.accuracy ?? ''}
                        onChange={(e) => setTingDoc({ ...tingDoc, accuracy: e.target.value })}
                    />
                </div>
                {coords.length === 2 && (
                    <div>
                        <TextField
                            id="lat2"
                            label="Latitude 2"
                            value={coords[1].lat}
                            onChange={(e) => handleCoordUpdate(1, { ...coords[1], lat: parseFloat(e.target.value) })}
                        />
                        <TextField
                            id="lng2"
                            label="Longitude 2"
                            value={coords[1].lng}
                            onChange={(e) => handleCoordUpdate(1, { ...coords[1], lng: parseFloat(e.target.value) })}
                        />
                    </div>
                )}

                <Button variant="contained" onClick={() => fetchLocationDetails(coords[0], 0)} sx={{ mt: 2 }}>Calculate distance</Button>

                <Button variant="contained" onClick={handleAddCoord} sx={{ mt: 2 }}>Add Second Point</Button>

                {processingLocation && <p>Processing location...</p>}
                {locationDescription && (
                    <div>
                        <p>{roadName}, {locationDescription}</p>
                        <Button variant="contained" onClick={appendToLocationDescription} sx={{ mt: 2 }}>Add to Description</Button>

                    </div>
                )}
            </Box>

            <MapContainer center={[coords[0].lat || 0, coords[0].lng || 0]} zoom={13} style={{ height: "400px", width: "100%" }}>
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                {coords.map((coord, index) => (
                    <Marker
                        key={index}
                        position={[coord.lat, coord.lng]}
                        draggable={true}
                        eventHandlers={{
                            dragend: (event) => {
                                const marker = event.target;
                                const newPosition = marker.getLatLng();
                                handleCoordUpdate(index, { lat: newPosition.lat, lng: newPosition.lng });
                            }
                        }}
                    />
                ))}
                {coords.length === 2 && (
                    <Polyline positions={coords.map(coord => [coord.lat, coord.lng])} />
                )}
                <LocationMarker setCoords={(coord) => handleCoordUpdate(coords.length - 1, coord)} />
            </MapContainer>
        </>
    );
}
