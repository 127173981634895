import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import useUserProfileStore from '../../stores/userProfileStore';

export default function UserContributorDetails() {

    const userDoc = useUserProfileStore(state => state.userDoc);
    const setUserDoc = useUserProfileStore(state => state.setUserDoc);

    const handleChange = (event) => {
        // Change value in setUserDoc based on what is typed
        setUserDoc({
            ...userDoc,
            [event.target.id]: event.target.value
        });
    };


    return (
        <>
            <div style={{ marginTop: '20px' }} />
            <div >
                User partner program details
            </div>
            {/* line break */}
            <div style={{ marginTop: '20px' }} />

            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                }}
            //   noValidate
            //   autoComplete="off"
            >
                <div>
                    <TextField
                        id="bankAccountName"
                        label="Bank Account Name"
                        value={userDoc.bankAccountName ?? ''}
                        multiline
                        maxRows={4}
                        onChange={handleChange}
                    />
                    <TextField
                        id="bankBranchCode"
                        label="Bank Branch Code"
                        value={userDoc.bankBranchCode ?? ''}
                        onChange={handleChange}
                    />
                    <TextField
                        id="bankAccountNumber"
                        label="Bank Account Number"
                        value={userDoc.bankAccountNumber ?? ''}
                        //   Width
                        style={{ width: '400px' }}
                        onChange={handleChange}

                    />
                </div>
                <div>
                    <TextField
                        id="paypalEmail"
                        label="Paypal Email"
                        value={userDoc.paypalEmail ?? ''}
                        style={{ width: '400px' }}
                        onChange={handleChange}
                    />
                </div>
            </Box>
        </>
    );
}