import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import useUserProfileStore from '../../stores/userProfileStore';
import moment from 'moment';

export default function UserExtraDetails() {
    const userDoc = useUserProfileStore(state => state.userDoc);
    const setUserDoc = useUserProfileStore(state => state.setUserDoc);

    const handleChange = (event) => {
        // Change value in setUserDoc based on what is typed
        if (event.target.value === 'admin') {
           return false
        }
        setUserDoc({
            ...userDoc,
            [event.target.id]: event.target.value
        });
    };



    return (
        <>
            <div style={{ marginTop: '20px' }} />
            <div >
                User extra details
            </div>
            {/* line break */}
            <div style={{ marginTop: '20px' }} />

            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                }}
            //   noValidate
            //   autoComplete="off"
            >
                <div>
                    <TextField
                        id="createdAt"
                        label="Date joined"
                        value={userDoc.createdAt ? moment.unix(userDoc.createdAt.seconds).format('MMMM Do YYYY') : ''}
                        multiline
                        maxRows={4}
                        onChange={handleChange}
                        // Make non-editable
                        InputProps={{
                            readOnly: true,
                        }}
                        variant='filled'
                    />
                    <TextField
                        id="updatedTime"
                        label="User last updated"
                        value={userDoc.updatedTime ? moment.unix(userDoc.updatedTime.seconds).format('MMMM Do YYYY') : ''}
                        onChange={handleChange}
                        // Make non-editable
                        InputProps={{
                            readOnly: true,
                        }}
                        variant='filled'
                    />
                    <TextField
                        id="expoPushToken"
                        label="Expo Push Token"
                        value={userDoc.expoPushToken ?? ''}
                        //   Width
                        style={{ width: '400px' }}
                        onChange={handleChange}

                        // Make non-editable
                        InputProps={{
                            readOnly: true,
                        }}
                        variant='filled'

                    />
                </div>
                <div>
                    <TextField
                        id="isEmailVerified"
                        label="Email is verified?"
                        value={userDoc.isEmailVerified ? 'Yes' : 'No'}
                        onChange={handleChange}
                        // Make non-editable
                        InputProps={{
                            readOnly: true,
                        }}
                        variant='filled'
                    />
                    <TextField
                        id="subscribeToNewsletter"
                        label="Subscribed to newsletter?"
                        value={userDoc.subscribeToNewsletter ? 'Yes' : 'No'}
                        onChange={handleChange}
                        // Make non-editable
                        InputProps={{
                            readOnly: true,
                        }}
                        variant='filled'
                    />
                    <TextField
                        id="userRole"
                        label="User role"
                        value={userDoc.userRole}
                        onChange={handleChange}
                        // InputProps={{
                        //     readOnly: true,
                        // }}
                        variant="filled"
                    />
                </div>
            </Box>
        </>
    );
}