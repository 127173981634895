import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import useTingStore from '../../stores/tingStore';
import { HomeRepairService } from '@mui/icons-material';

export default function TingContentDetails() {
    const tingDoc = useTingStore(state => state.tingDoc);
    const setTingDoc = useTingStore(state => state.setTingDoc);

    const handleChange = (event) => {
        // Change value in setUserDoc based on what is typed
        setTingDoc({
            ...tingDoc,
            [event.target.id]: event.target.value
        });
    };



    return (
        <>
            <div style={{ marginTop: '20px' }} />
            <div >
                Ting Content Details
            </div>
            {/* line break */}
            <div style={{ marginTop: '20px' }} />

            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                }}
            //   noValidate
            //   autoComplete="off"
            >
                <div>
                    <TextField
                        id="contentType"
                        label="Content"
                        value={tingDoc.contentType ?? ''}
                        onChange={handleChange}
                        // disabled
                        variant="filled"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    {tingDoc.postImg && <a href={tingDoc.postImg}>Download image</a>}
                    {tingDoc.postVideo && <a href={tingDoc.postVideo}>Download video</a>}

                </div>
            </Box>
        </>
    );
}