import React, { useState, useEffect } from 'react';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { collection, query, getDocs, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../firebase';
import './wapParks.css';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

const WapParks = () => {
  const [parks, setParks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isPaneOpen, setIsPaneOpen] = useState(false);
  const [currentPark, setCurrentPark] = useState(null);

  // A piece of state to keep track of the new apiIDs entry
  const [newApiId, setNewApiId] = useState({ key: '', value: '' });

  useEffect(() => {
    const fetchParks = async () => {
      setLoading(true);
      const q = query(collection(db, "whatsAppGroups"));

      const querySnapshot = await getDocs(q);
      const parksData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      console.log('parksData', parksData);
      setParks(parksData);
      setLoading(false);
    };

    fetchParks();
  }, []);

  const openSlider = (parkId) => {
    const parkData = parks.find(park => park.id === parkId);
    setCurrentPark(parkData);
    setIsPaneOpen(true);
  }

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    if (index !== undefined) { // This indicates a change in gateTimes array
      const updatedGateTimes = currentPark.gateTimes;
      updatedGateTimes[index][name] = value;
      setCurrentPark({ ...currentPark, gateTimes: updatedGateTimes });
    } else {
      setCurrentPark({ ...currentPark, [name]: value });
    }
  }

// const updatedPark = {
//     id: "Test",
//     gateTimes: [
//         {
//             months: ['January', 'December', 'November', 'February'],
//             campOpen: '05:00',
//             gatesOpen: '05:00',
//             campClose: '19:00',
//             gatesClose: "19:00",
//         },
//         {
//             months: ['March', 'October', 'May', 'June', 'July', 'April', 'August'],
//             campOpen: '06:00',
//             gatesOpen: '06:00',
//             campClose: '18:00',
//             gatesClose: "18:00",
//         },
//     ],
//     parkName: "Testks Groups",
//     apiIDs: {
//         "Testks 1": "27796646029-1561888609",
//         "Testks 2": "27796646029-1561888646",
//         "Testks 3": "27796646029-1561888623",
//         "Testks 4": "27796646029-1564228173",
//         "Testks 5": "27796646029-1564228204",
//         "Testks 6": "27796646029-1564228218",
//         "Testks 7": "27796646029-1564228242",
//         "Testks 8": "27796646029-1564228257",
//         "Testks 9": "27796646029-1564228278",
//         "Testks 10": "27796646029-1564228293",
//         "Testks 11": "27796646029-1564228307"
//     },
//     "linkToJoin": "https://chat.whatsapp.com/Hv9vwTqnS3lCLrJLMgY3MS"
// }




  // Handle the addition of a new apiIDs entry
  const handleAddApiId = () => {
    setCurrentPark({ ...currentPark, apiIDs: { ...currentPark.apiIDs, [newApiId.key]: newApiId.value } });
    setNewApiId({ key: '', value: '' }); // Clear the input field
  }

  const handleSave = async () => {
    const parkRef = doc(db, "whatsAppGroups", currentPark.id);
    await updateDoc(parkRef, currentPark);

    const updatedParks = parks.map(park =>
      park.id === currentPark.id ? currentPark : park
    );
    console.log('updatedParks', updatedParks);
    setParks(updatedParks);
    setIsPaneOpen(false);
  }

  return (
    <div className='users__container'>
      <h1 className='users__heading'>Parks</h1>
      <ul className='users__list'>
        {parks.map(park => (
          <li key={park.id} className='users__list-item'>
            {park.parkName}
            <button className='users__list-item-button' onClick={() => openSlider(park.id)}>View Details</button>
          </li>
        ))}
      </ul>
      <SlidingPane
        isOpen={isPaneOpen}
        title='Park Details'
        onRequestClose={() => {
          setIsPaneOpen(false);
        }}>
        {/* Display park details here */}
        {currentPark && (
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
          >
            <div>
              <TextField
                id="parkName"
                label="Park Name"
                name="parkName"
                value={currentPark.parkName}
                onChange={handleInputChange}
              />
              <TextField
                id="linkToJoin"
                label="Link To Join"
                name="linkToJoin"
                value={currentPark.linkToJoin}
                onChange={handleInputChange}
              />
              <h3>API IDs</h3>
              {Object.entries(currentPark.apiIDs).map(([key, value]) => (
                <TextField
                  id={key}
                  label={key}
                  name={key}
                  value={value}
                  onChange={handleInputChange} // You would need to modify this function to handle nested state
                />
              ))}
              <TextField
                id="newApiKeyId"
                label="New Group Name"
                value={newApiId.key}
                onChange={(e) => setNewApiId({ ...newApiId, key: e.target.value })}
              />
              <TextField
                id="newApiValueId"
                label="New Group API Code"
                value={newApiId.value}
                onChange={(e) => setNewApiId({ ...newApiId, value: e.target.value })}
              />
              <Button variant="contained" color="primary" onClick={handleAddApiId}>
                Add API ID
              </Button>
              {currentPark.gateTimes.map((time, index) => (
                <div key={index}>
                  <h3>Gate Time {index + 1}</h3>
                  {time.months && time.months.length > 0 && time.months.map((month, monthIndex) => (
                    <div key={monthIndex}>
                      <TextField
                        id={`months${index}-${monthIndex}`}
                        label="Month"
                        name="month"
                        value={month}
                        onChange={(e) => handleInputChange(e, index)}
                        disabled
                      />
                    </div>
                  ))}
                  <TextField
                    id={`campOpen${index}`}
                    label="Camp Open"
                    name="campOpen"
                    value={time.campOpen}
                    onChange={(e) => handleInputChange(e, index)}
                    disabled
                  />
                  <TextField
                    id={`gatesOpen${index}`}
                    label="Gates Open"
                    name="gatesOpen"
                    value={time.gatesOpen}
                    onChange={(e) => handleInputChange(e, index)}
                    disabled
                  />
                  <TextField
                    id={`campClose${index}`}
                    label="Camp Close"
                    name="campClose"
                    value={time.campClose}
                    onChange={(e) => handleInputChange(e, index)}
                    disabled
                  />
                </div>
              ))}
            </div>
            <Button variant="contained" color="primary" onClick={handleSave}>
              Save
            </Button>
          </Box>
        )}
      </SlidingPane>
    </div>
  );
};

export default WapParks;

