import * as React from 'react';

// Components
import TingEssentialDetails from './TingEssentialDetails';
import TingLocationDetails from './TingLocationDetails';
import TingContentDetails from './TingContentDetails';
import TingSocialDetails from './TingSocialDetails';

export default function EditTing() {

    return (
        <>
            <TingEssentialDetails />
            <TingLocationDetails />
            <TingContentDetails />
            <TingSocialDetails />
        </>
    );
}