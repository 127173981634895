import React from "react";
import styled from "styled-components";

// import TestVariationDetails from "./TestVariationDetails";

import useTestStore from '../../stores/testStore';

// Styled component for the data container
const DataContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 20px;
`;

// Styled component for the data title
const DataTitle = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
`;

// Styled component for the data item container
const DataItem = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 5px;
  color: white;
  font-weight: bold;
  background-color: 'black';
`;

// Styled component for the data item key
const DataItemKey = styled.div`
  background-color: gray;
  padding: 5px;
  color: white;
  border-radius: 5px;
`;

// Styled component for the data item value
const DataItemValue = styled.div`
background-color: ${props => props.bgColor};
padding: 5px;
color: white;
border-radius: 5px;
max-width: 60px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Table = styled.div`
  display: table;
`;

const TableRow = styled.div`
  display: table-row;
`;

const TableCell = styled.div`
  display: table-cell;
  padding: 8px;
  width: 50px;
`;



// React component for the page
function SegmentPage() {

    const testDoc = useTestStore(state => state.testDoc);

    const [combinations, setCombinations] = React.useState([]);

    const handleGetCombinations = () => {
        let tempCombinations = [];
        const titles = testDoc.titles.length;
        const thumbnails = testDoc.thumbnails.length;
        const numCombinations = titles * thumbnails;
        for (let i = 0; i < titles; i++) {
            for (let j = 0; j < thumbnails; j++) {
                const currentCombination = `${i}${j}`;
                tempCombinations.push(currentCombination);
            }
        }
        console.log('tempCombinations: ', tempCombinations);
        setCombinations(tempCombinations);
    }

    React.useEffect(() => {
        handleGetCombinations();
        console.log('testDoc: ', testDoc);
    }, [])

    const SegmentedData = ({ data, label, type, extraData }) => {
        const dataEntries = Object.entries(data);

        // Get average watchtime from data and extraData
        // const averageWatchtime = (data / extraData).toFixed(1);

        const colorScale = Math.max(...Object.values(data));

        // Sort the data by combinations 
        const sortedData = dataEntries.sort((a, b) => combinations.indexOf(a[0]) - combinations.indexOf(b[0]));
        // Sort by highest ctr 
        console.log('');
        return (
            <>
                <Container>
                    <DataTitle>{label}</DataTitle>

                    <Table>
                        {sortedData.map((entry) => (
                            <TableRow key={entry[0]}>
                                {type === 'key' && <TableCell>
                                    <DataItemKey>{entry[0]}</DataItemKey>
                                </TableCell>}
                                {type !== 'key' && < TableCell >
                                    <DataItemValue bgColor={`rgb(${200 - (entry[1] / colorScale) * 200}, ${entry[1] / colorScale * 200}, 0)`}
                                        style={{
                                            border: data[entry[0]].toString().substring(0, 5) === colorScale.toString().substring(0, 5) ? '3px solid black' : 'none',
                                            padding: data[entry[0]].toString().substring(0, 5) === colorScale.toString().substring(0, 5) ? '2px' : '5px',
                                        }}
                                    >
                                        {type === 'average' && (data[entry[0]] / extraData[entry[0]]).toFixed(1)}
                                        {type === 'ctr' && ((data[entry[0]] / extraData[entry[0]]) * 100).toFixed(1) + "%"}
                                        {!type && data[entry[0]]}
                                    </DataItemValue>
                                </TableCell>}
                            </TableRow>
                        ))}
                    </Table>
                </Container>

            </>
        );
    };


    return (
        <div>

            {testDoc && <DataContainer>
                <SegmentedData data={testDoc?.segmentedViews} label="Combo" type='key' />
                <SegmentedData data={testDoc?.segmentedViews} label="CTR" extraData={testDoc?.segmentedLoads} type='ctr' />
                <SegmentedData data={testDoc?.segmentedLoads} label="Impressions" />
                <SegmentedData data={testDoc?.segmentedViews} label="Views" />
                <SegmentedData data={testDoc?.segmentedWatchtime} label="AVG Watchtime" extraData={testDoc?.segmentedViews} type='average' />
                <SegmentedData data={testDoc?.segmentedLikes} label="Likes" />
                <SegmentedData data={testDoc?.segmentedComments} label="Comments" />
            </DataContainer>}
        </div>
    );
}

export default SegmentPage;
