import React from 'react';
import './FilterBar.css';

const FilterBar = ({ filters, setFilters }) => {
  const handleDateChange = (direction) => {
    const currentDate = new Date(filters.date);
    currentDate.setDate(currentDate.getDate() + direction);
    setFilters({ ...filters, date: currentDate.toISOString().split('T')[0] });
  };

  const handleVideosChange = () => {
    setFilters({ ...filters, showVideos: !filters.showVideos });
  };

  const handleVerticalVideosChange = () => {
    const newShowVerticalVideos = !filters.showVerticalVideos;
    setFilters({
      ...filters,
      showVerticalVideos: newShowVerticalVideos,
      showVideos: newShowVerticalVideos ? true : filters.showVideos, // Ensure showVideos is true if enabling showVerticalVideos
    });
  };

  return (
    <div className="filter__bar">
      <div className="filter__group">
        <label className="filter__label">
          <input
            type="checkbox"
            checked={filters.showVideos}
            onChange={handleVideosChange}
            className="filter__checkbox"
          />
          Videos
        </label>
        <label className="filter__label">
          <input
            type="checkbox"
            checked={filters.showVerticalVideos}
            onChange={handleVerticalVideosChange}
            className="filter__checkbox"
          />
          Vertical Videos
        </label>
      </div>
      <div className="filter__group">
        <label className="filter__label">
          Min Likes:
          <input
            type="number"
            value={filters.minLikes}
            onChange={(e) => setFilters({ ...filters, minLikes: Number(e.target.value) })}
            className="filter__input"
          />
        </label>
      </div>
      <div className="filter__group">
        <label className="filter__label">
          Min Comments:
          <input
            type="number"
            value={filters.minComments}
            onChange={(e) => setFilters({ ...filters, minComments: Number(e.target.value) })}
            className="filter__input"
          />
        </label>
      </div>
      <div className="filter__group date__filter">
        <button onClick={() => handleDateChange(-1)}>&lt;</button>
        <label className="filter__label">
          Date:
          <input
            type="date"
            value={filters.date}
            onChange={(e) => setFilters({ ...filters, date: e.target.value })}
            className="filter__input"
          />
        </label>
        <button onClick={() => handleDateChange(1)}>&gt;</button>
      </div>
    </div>
  );
};

export default FilterBar;
