import React, { useEffect, useState } from 'react';
import './Users.css';

// Data
// Components
import Table from '../../../../components/Table/Table';

// Get users
import { collection, getDocs, limit, orderBy, query, where } from 'firebase/firestore';
import { db } from '../../../../firebase';

const Users = () => {
  const [usersDB, setUsersDB] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState({ email: '', firstName: '', lastName: '', username: '' });


  const columns = [
    { label: "First name", accessor: "firstName", sortable: true, date: false },
    { label: "Last name", accessor: "lastName", sortable: false, date: false },
    { label: "Email", accessor: "email", sortable: true, date: false },
    { label: "Phone number", accessor: "phoneNumber", sortable: true, date: false },
    { label: "Joined", accessor: "createdAt", sortable: true, date: true, sortbyOrder: "desc" },
    { label: "Edit", accessor: "edit", sortable: false, date: false },
  ];


  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    setLoading(true);
    const q = query(collection(db, "users"), orderBy("createdAt", "desc"), limit(50));

    const querySnapshot = await getDocs(q);
    const users = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    setUsersDB(users);
    setLoading(false);
  };

  const handleFilterChange = (event) => {
    setFilter({
      ...filter,
      [event.target.name]: event.target.value,
    });
  };

  const applyFilter = async (event) => {
    event.preventDefault();
    setLoading(true);

    // If no filter, give an error message
    if (!Object.values(filter).some((x) => (x !== null && x !== ''))) {
      alert('Please enter a filter');
      setLoading(false);
      return;
    }

    let q = collection(db, "users");
    Object.keys(filter).forEach((key) => {
      if (filter[key]) {
        q = query(q, where(key, '==', filter[key]), limit(50));
      }
    });

    const querySnapshot = await getDocs(q);
    const users = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    setUsersDB(users);
    setLoading(false);
  };



  return (
    <div className='users__container'>
      <h1 className='users__heading'>Users</h1>

      <form className="filter-bar" onSubmit={applyFilter}>
        <input type="text" name="email" placeholder="Email" value={filter.email} onChange={handleFilterChange} />
        <input type="text" name="firstName" placeholder="First Name" value={filter.firstName} onChange={handleFilterChange} />
        <input type="text" name="lastName" placeholder="Last Name" value={filter.lastName} onChange={handleFilterChange} />
        <input type="text" name="username" placeholder="Username" value={filter.username} onChange={handleFilterChange} />
        {/* <input type="text" name="phoneNumber" placeholder="Phone Number" value={filter.phoneNumber} onChange={handleFilterChange} /> */}
        <button type="submit">Apply Filter</button>
      </form>

      <button className='users__button' onClick={fetchUsers}>
        Fetch new users
      </button>

      {loading ?
        <div>Loading...</div>
        :
        <Table
          caption="List of users registered"
          data={usersDB}
          columns={columns}
          path="users"
        />
      }
    </div>
  );
};

export default Users;