import { useState } from "react";

const TableHead = ({ columns, handleSorting }) => {

    const [sortField, setSortField] = useState("");
    const [order, setOrder] = useState("asc");

    const handleSortingChange = (accessor) => {
        console.log('Accessor', accessor);
        const sortOrder =
            accessor === sortField && order === "asc" ? "desc" : "asc";
        setSortField(accessor);
        setOrder(sortOrder);
        handleSorting(accessor, sortOrder);
    };

    return (
        <thead style={
            {
                backgroundColor: "#f5f5f5",
                borderBottom: "1px solid #ddd",
                borderTop: "1px solid #ddd",
                fontSize: "14px",
                fontWeight: "bold",
                textAlign: "left",
                verticalAlign: "middle",
            }}>
            <tr>
                {columns.map(({ label, accessor, sortable }) => {
                    const cl = sortable
                        ? sortField === accessor && order === "asc"
                            ? "up"
                            : sortField === accessor && order === "desc"
                                ? "down"
                                : "default"
                        : "";
                    return <th key={accessor} onClick={sortable ? () => handleSortingChange(accessor) : null}
                        className={cl}
                    >{label}</th>;
                })}
            </tr>
        </thead>
    );
};

export default TableHead;