import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import useUserProfileStore from '../../stores/userProfileStore';

export default function UserEssentialDetails() {
    const userDoc = useUserProfileStore(state => state.userDoc);
    const setUserDoc = useUserProfileStore(state => state.setUserDoc);

    const handleChange = (event) => {
        // Change value in setUserDoc based on what is typed
        setUserDoc({
            ...userDoc,
            [event.target.id]: event.target.value
        });
    };
    return (
        <>
            <div style={{ marginTop: '20px' }} />
            <div >
                User essential details
            </div>
            {/* line break */}
            <div style={{ marginTop: '20px' }} />

            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                }}
            //   noValidate
            //   autoComplete="off"
            >
                <div>
                    <TextField
                        id="firstName"
                        label="First Name"
                        value={userDoc.firstName}
                        onChange={handleChange}
                    />
                    <TextField
                        id="lastName"
                        label="Last Name"
                        value={userDoc.lastName}
                        onChange={handleChange}
                    />
                    <TextField
                        id="email"
                        label="Email"
                        value={userDoc.email}
                        //   Width
                        style={{ width: '400px' }}
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <TextField
                        id="phoneNumber"
                        label="Phone number"
                        value={userDoc.phoneNumber}
                        onChange={handleChange}
                    />
                    <TextField
                        id="username"
                        label="Username"
                        value={userDoc.username}
                        onChange={handleChange}
                        // Make non-editable
                        InputProps={{
                            readOnly: true,
                        }}
                        variant="filled"
                    />
                    <TextField
                        id="uid"
                        label="User ID"
                        value={userDoc.uid}
                        style={{ width: '400px' }}
                        onChange={handleChange}
                        // Make non-editable
                        InputProps={{
                            readOnly: true,
                        }}
                        variant="filled"
                    />
                </div>
            </Box>
        </>
    );
}