import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase';
import useAuthStore from '../stores/authStore';
import useUserProfileStore from '../stores/userProfileStore';

import './ProtectedRoutes.css';

const ProtectedRoute = ({ children }) => {
  const user = useAuthStore(state => state.user);
  const setUser = useAuthStore(set => set.setUser);
  const logout = useAuthStore(state => state.logout);
  const admin = useUserProfileStore(state => state.admin);
  const setAdmin = useUserProfileStore(set => set.setAdmin);

  const [initializing, setInitializing] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setInitializing(false);
      console.log(currentUser);
      // Check custom claims and set admin state
      if (currentUser) {
        currentUser.getIdTokenResult().then((idTokenResult) => {
          console.log('idTokenResult ', idTokenResult.claims.admin);
          // Confirm the user is an Admin.
          if (idTokenResult.claims.admin || idTokenResult.claims.accountant) {
            // Show admin UI.
            console.log('Admin');
            // Set admin state
            setAdmin(true);
          } else {
            // Hide admin UI.
            console.log('Not admin');
            // Set admin state
            setAdmin(false);
          }
        }).catch((error) => {
          console.log(error);
        });
      }

    });

    unsubscribe();

  }, [setUser]);

  if (initializing) return (
    <div>
      <h1 className='protectedRoutes__loading'>Loading...</h1>
    </div>
  )

  if (!user
    // || !signedInUserDoc
    // If not admin
    || !admin
    // || signedInUserDoc.userRole !== 'admin'
  ) {
    // console.log('signedInUserDoc ', signedInUserDoc);
    if (!admin) {
      // Alert user that they are not an admin
      alert('You are not an admin');
      // Logout user
      logout();
    }
    return <Navigate to='/login' />;
  }


  return children;

};

export default ProtectedRoute;
