import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import useTestStore from '../../stores/testStore';
import moment from 'moment';
import Checkbox from '@mui/material/Checkbox';

export default function TestEssentialDetails() {
    const testDoc = useTestStore(state => state.testDoc);
    const setTestDoc = useTestStore(state => state.setTestDoc);
    const [active, setActive] = React.useState(false);

    React.useEffect(() => {
        if (testDoc.active) {
            setActive(true);
        }
    }, [])

    const handleActiveChange = (event) => {
        if (active) {
            setActive(false);
            setTestDoc({ ...testDoc, active: false });
        }
        else {
            setActive(true);
            setTestDoc({ ...testDoc, active: true });
        }
    };

    const handleChange = (event) => {
        // Change value in setUserDoc based on what is typed

        setTestDoc({
            ...testDoc,
            [event.target.id]: event.target.value
        });

    };



    return (
        <>
            <div style={{ marginTop: '20px' }} />
            <div >
                Test Core Details
            </div>
            {/* line break */}
            <div style={{ marginTop: '20px' }} />

            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                }}
            // noValidate
            // autoComplete="off"
            >
                <div>
                    <TextField
                        id="testTitle"
                        label="Title of Test"
                        value={testDoc.testTitle ?? ''}
                        onChange={handleChange}
                    />

                    <Checkbox
                        checked={active}
                        onChange={handleActiveChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />

                    <TextField
                        id="active"
                        label="Active"
                        value={testDoc.active ?? ''}
                        onChange={handleActiveChange}
                    />

                </div>
                <div>
                    <TextField
                        id="metaDescription"
                        label="Description"
                        style={{ width: '500px' }}
                        value={testDoc.metaDescription ?? ''}
                        maxRows={4}
                        multiline
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <TextField
                        id="postTime"
                        label="Time of Test"
                        value={testDoc.postTime ? moment.unix(testDoc.postTime.seconds).calendar() : ''}
                        onChange={handleChange}
                        // disabled
                        variant="filled"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <TextField
                        id="park"
                        label="Park"
                        value={testDoc.park ?? ''}
                        onChange={handleChange}
                    />

                </div>

            </Box>
        </>
    );
}