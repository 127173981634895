import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import useTestStore from '../../../stores/testStore';

export default function TitleVariations() {
    const newTestDoc = useTestStore(state => state.newTestDoc);
    const setNewTestDoc = useTestStore(set => set.setNewTestDoc);

    const handleChange = (event) => {
        console.log('event.target.id', event.target.id);
        // Change value in setUserDoc based on what is typed
        // Edit title array
        //  = event.target.value;
        const tempTitles = newTestDoc.titles;
        tempTitles[event.target.id] = event.target.value;

        setNewTestDoc({
            ...newTestDoc,
            titles: tempTitles
        });
    };


    return (
        <>
            {/* <div style={{ marginTop: '20px' }} /> */}
            <div >
                <br />
                Choose 3 different titles to test:
            </div>
            {/* line break */}
            {/* <div style={{ marginTop: '20px' }} /> */}

            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                }}
            //   noValidate
            //   autoComplete="off"
            >

                <div style={{ display: 'flex' }}>


                    <TextField
                        id={`0`}
                        label={`0`}
                        value={newTestDoc.titles[0]}
                        onChange={handleChange}
                        style={{ width: '500px' }}
                    />
                </div>
                <div>
                    <TextField
                        id={`1`}
                        label={`1`}
                        value={newTestDoc.titles[1]}
                        onChange={handleChange}
                        style={{ width: '500px' }}

                    />
                </div>
                <div>
                    <TextField
                        id={`2`}
                        label={`2`}
                        value={newTestDoc.titles[2]}
                        onChange={handleChange}
                        style={{ width: '500px' }}

                    />
                </div>

            </Box >
            {/* </div > */}

        </>
    );
}