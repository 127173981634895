import create from "zustand";
const initialState = {
    newTestDoc: {
        active: false,
        comments: [],
        ctr: {
            '00': 0,
            '01': 0,
            '02': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '20': 0,
            '21': 0,
            '22': 0,
        },
        imageHeight: 0,
        imageWidth: 0,
        likes: [],
        metaDescription: '',
        park: '',
        postVideo: '',
        segmentedComments: {
            '00': 0,
            '01': 0,
            '02': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '20': 0,
            '21': 0,
            '22': 0,
        },
        segmentedLikes: {
            '00': 0,
            '01': 0,
            '02': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '20': 0,
            '21': 0,
            '22': 0,
        },
        segmentedLoads: {
            '00': 0,
            '01': 0,
            '02': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '20': 0,
            '21': 0,
            '22': 0,
        },
        segmentedViews: {
            '00': 0,
            '01': 0,
            '02': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '20': 0,
            '21': 0,
            '22': 0,
        },
        segmentedWatchtime: {
            '00': 0,
            '01': 0,
            '02': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '20': 0,
            '21': 0,
            '22': 0,
        },
        testTitle: '',
        testType: 'video',
        thumbnails: [],
        tingedBy: '',
        titles: [],
        totalLoads: [],
        totalViews: [],
        totalWatchtime: 0,
        uniqueLoads: [],
    },
}
const useTestStore = create((set) => ({
    ...initialState,
    testDoc: {},
    setTestDoc: (testDoc) => set((state) => ({ testDoc })),
    setNewTestDoc: (test) => {
        set((state) => ({
            ...state,
            newTestDoc: test
        }))
    },
    restoreNewTestDoc: () => set(initialState),

}));

export default useTestStore;
