
import { collection, query, orderBy, getDocs, where, limit } from "firebase/firestore";
import { db } from '../../firebase';

export const fetchPostTests = async (pageSize) => {
    console.log('Fetching new Post Tets');
    let list = [];

    try {
        const q = query(collection(db, "postTests"),
            orderBy('postTime', 'desc'),
            limit(pageSize));

        const querySnapshot = await getDocs(q);

        querySnapshot.forEach((doc) => {
            console.log('doc', doc.id);
            const {
                ...test
            } = doc.data();
            list.push({
                ...test,
                id: doc.id,
            });
        })
        console.log('list', list);
        return {
            list, loaded: false, lastVisible: querySnapshot.docs[querySnapshot.docs.length - 1], firstVisible: querySnapshot.docs[0]
        }
    } catch (e) {
        console.log(e);
    }

};