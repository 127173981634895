import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import useTingStore from '../../stores/tingStore';

export default function TingSocialDetails() {
    const tingDoc = useTingStore(state => state.tingDoc);
    const setTingDoc = useTingStore(state => state.setTingDoc);

    const handleChange = (event) => {
        // Change value in setUserDoc based on what is typed
        setTingDoc({
            ...tingDoc,
            [event.target.id]: event.target.value
        });
    };



    return (
        <>
            <div style={{ marginTop: '20px' }} />
            <div >
                Ting Social Details
            </div>
            {/* line break */}
            <div style={{ marginTop: '20px' }} />

            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                }}
            //   noValidate
            //   autoComplete="off"
            >
                <div>
                    <TextField
                        id="likes"
                        label="Likes"
                        value={tingDoc.likes ? tingDoc.likes.length : 0}
                        onChange={handleChange}
                        // disabled
                        variant="filled"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <TextField
                        id="comments"
                        label="Comments"
                        value={tingDoc.comments ? tingDoc.comments.length : 0}
                        onChange={handleChange}
                        // disabled
                        variant="filled"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </div>
                <div>
                    <TextField
                        id="userId"
                        label="Tinger"
                        value={tingDoc.userId ?? ''}
                        style={{ width: '370px' }}
                        onChange={handleChange}
                    />
                    <TextField
                        id="adminTingedForWho"
                        label="Admin Tinged For Who"
                        value={tingDoc.adminTingedForWho ?? ''}
                        style={{ width: '370px' }}
                        onChange={handleChange}
                    />
                </div>
            </Box>
        </>
    );
}