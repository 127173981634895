import React from 'react'
import moment from 'moment';

const TableBody = ({ tableData, columns, path }) => {
    React.useEffect(() => {

        console.log('columns', columns);
    }, [])
    return (
        <tbody>
            {tableData.map((data) => {
                return (
                    <tr key={data.id}
                        style={{
                            borderBottom: "1px solid #ddd",
                            textAlign: "left",
                            verticalAlign: "middle",
                            border: "1px solid #ddd"
                        }}>
                        {columns.map(({ accessor, date, label, link }) => {
                            const tData = data[accessor] ? data[accessor] : "——";
                            return <td key={accessor}>{
                                date ?

                                    (moment().diff(moment.unix(tData), 'days') < 2) ?
                                        moment.unix(tData.seconds).calendar()
                                        :
                                        moment.unix(tData.seconds).format('MMMM Do YYYY, h:mm a')
                                    :
                                    (label === "Edit" ?
                                        <a href={`/${path}/${data.id}`}>Edit</a>
                                        :
                                        (label === "Likes" || label === "Total Loads" || label === "Total Views"?
                                            tData.length :
                                            (link && tData !== "——" ?
                                                <a href={tData} target="_blank" rel="noreferrer"> Download</a>
                                                : 
                                                tData)
                                        ))


                            }</td>;
                        })}
                    </tr>
                );
            })}
        </tbody>
    );
};

export default TableBody;