import * as React from 'react';

// Components
import TestEssentialDetails from './TestEssentialDetails';
import TestResultsDetails from './TestResultsDetails';
import TestContentDetails from './TestContentDetails';
import TestSocialDetails from './TestSocialDetails';
import TestVariationDetails from './TestVariationDetails';
import TestSignificanceTest from './TestSignificanceTest';
import TestDelete from './TestDelete';

export default function EditTest({testDoc, testId }) {

    return (
        <>
            <TestEssentialDetails />
            <TestSocialDetails />
            <TestSignificanceTest />
            <TestResultsDetails />
            <TestVariationDetails />
            <TestContentDetails />
            <TestDelete testDoc={testDoc} testId={testId} />

        </>
    );
}