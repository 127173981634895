import useUserProfileStore from '../../stores/userProfileStore';

import React from 'react';


export default function ProfilePhoto() {
    const userDoc = useUserProfileStore(state => state.userDoc);

    return (
        <>
            <div className='user__profile-photo'>
                <img
                    style={{
                        width: '150px',
                        height: '150px',
                        borderRadius: '50%',
                    }}
                    src={userDoc.userImg ? userDoc.userImg : 'https://cdn1.iconfinder.com/data/icons/random-115/24/person-1024.png'}
                    alt='profile' />
            </div>
        </>
    );
}
