import React, { useState } from 'react';
import './ContentPage.css';
import FilterBar from '../FilterBar/FilterBar';
import ContentList from '../ContentList/ContentList';

const ContentPage = () => {
    const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format
    const [filters, setFilters] = useState({
      showVideos: false,
      showVerticalVideos: false,
      minLikes: 0,
      minComments: 0,
      date: today,
    });

  return (
    <div className='grid__container'>
      <h1 className='grid__heading'>Today's Content</h1>
      <FilterBar filters={filters} setFilters={setFilters} />
      <ContentList filters={filters} />
    </div>
  );
};

export default ContentPage;
