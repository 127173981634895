import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import useTestStore from '../../../stores/testStore';
import moment from 'moment';
import Checkbox from '@mui/material/Checkbox';

export default function TestEssentialDetails() {
    const newTestDoc = useTestStore(state => state.newTestDoc);
    const setNewTestDoc = useTestStore(set => set.setNewTestDoc);

    const [active, setActive] = React.useState(false);

    React.useEffect(() => {
        if (newTestDoc.active) {
            setActive(true);
        }
    }, [])

    const handleActiveChange = (event) => {
        if (active) {
            setActive(false);
            setNewTestDoc({ ...newTestDoc, active: false });
        }
        else {
            setActive(true);
            setNewTestDoc({ ...newTestDoc, active: true });
        }
    };

    const handleChange = (event) => {
        // Change value in setUserDoc based on what is typed

        setNewTestDoc({
            ...newTestDoc,
            [event.target.id]: event.target.value
        });

    };



    return (
        <>
            <div style={{ marginTop: '20px' }} />
            <div >
                Test Core Details
            </div>
            {/* line break */}
            <div style={{ marginTop: '20px' }} />

            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                }}
            // noValidate
            // autoComplete="off"
            >
                <div>
                    <TextField
                        id="testTitle"
                        label="Title of Test"
                        value={newTestDoc.testTitle ?? ''}
                        onChange={handleChange}
                    />

                    <Checkbox
                        checked={active}
                        onChange={handleActiveChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />

                    <TextField
                        id="active"
                        label="Active"
                        value={newTestDoc.active ?? ''}
                        onChange={handleActiveChange}
                    />

                </div>
                <div>
                    <TextField
                        id="metaDescription"
                        label="Description - Goes on the app"
                        style={{ width: '500px' }}
                        value={newTestDoc.metaDescription ?? ''}
                        maxRows={4}
                        multiline
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <TextField
                        id="park"
                        label="Park"
                        value={newTestDoc.park ?? ''}
                        onChange={handleChange}
                    />
                    <TextField
                        id="tingedBy"
                        label="Tinged By"
                        value={newTestDoc.tingedBy ?? ''}
                        onChange={handleChange}
                    />

                </div>

            </Box>
        </>
    );
}