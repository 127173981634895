import { db } from '../../firebase';
import { collection, query, orderBy, getDoc, where, limit, doc } from "firebase/firestore";

export const getUser = async (id) => {
    console.log('Fetching user');

    try {
        const q = doc(db, "users", id);

        const querySnapshot = await getDoc(q);

        const userDocData = querySnapshot.data()
        console.log('userDoc: ', userDocData);
        return {
            userDocData, loaded: false
        }
    } catch (e) {
        console.log(e);
    }

};