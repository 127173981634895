import { db } from '../../firebase';
import { collection, query, orderBy, getDoc, where, limit, doc } from "firebase/firestore";

export const getTing = async (id) => {
    console.log('Fetching Ting');

    try {
        const q = doc(db, "posts", id);

        const querySnapshot = await getDoc(q);

        let tingDocDataReceived = querySnapshot.data()
        // Get doc ID
        tingDocDataReceived = {
            ...tingDocDataReceived,
            postId: querySnapshot.id
        }

        return {
            tingDocDataReceived, loaded: false
        }
    } catch (e) {
        console.log(e);
    }

};