//import useState hook to create menu collapse state
import React from "react";

//import sidebar css from react-pro-sidebar module and our custom css 
import "react-pro-sidebar/dist/css/styles.css";
import "./SiteHeader.css";

import useAuthStore from "../../stores/authStore";
import { useNavigate } from 'react-router-dom';

const SiteHeader = ({ mode }) => {
    const user = useAuthStore(state => state.user);
    const logout = useAuthStore(state => state.logout);
    const navigate = useNavigate();

    console.log('window.location.pathname: ', window.location.pathname === "/posttests");
    return (
        <>

            <div
                className={`siteheader_container ${mode}`}
            >
                <div className="siteheader_row">

                    {/* <div className="siteheader_box">
                        <div className="siteheader_logo">
                            <img src="logo.jpg"
                                alt="logo"
                                // border radius
                                style={{ borderRadius: 5 }}
                            />
                        </div>
                    </div> */}

                    <h1 className={`siteheader_logo ${mode}`}>
                        Latest Sightings Admin Panel
                    </h1>
                    {/* <div className={`siteheader_clickable ${mode}`}>
                        <button onClick={() => navigate('/privacy-policy')}>Privacy Policy</button>
                    </div> */}

                    <div className="siteheader_box group">

                        {user && <>
                            <div className={`siteheader_clickable ${mode} ${window.location.pathname === "/" && 'active'}`}>
                                <button onClick={() => navigate('/')}>Dashboard</button>
                            </div>
                            <div className={`siteheader_clickable ${mode} ${window.location.pathname === "/content" && 'active'}`}>
                                <button onClick={() => navigate('/content')}>Content</button>
                            </div>
                            <div className={`siteheader_clickable ${mode} ${window.location.pathname === "/users" && 'active'}`}>
                                <button onClick={() => navigate('/users')}>Users</button>
                            </div>
                            <div className={`siteheader_clickable ${mode} ${window.location.pathname === "/tings" && 'active'}`}>
                                <button onClick={() => navigate('/tings')}>Tings</button>
                            </div>
                            <div className={`siteheader_clickable ${mode} ${(window.location.pathname === "/posttests") && 'active'}`}>
                                <button onClick={() => navigate('/posttests')}>Post Tests</button>
                            </div>
                            <div className={`siteheader_clickable ${mode} ${(window.location.pathname === "/whatsapp-parks") && 'active'}`}>
                                <button onClick={() => navigate('/whatsapp-parks')}>WhatsApp</button>
                            </div>
                            <div className={`siteheader_clickable ${mode} ${window.location.pathname === "/settings" && 'active'}`}>
                                <button onClick={() => navigate('/settings')}>Settings</button>
                            </div>
                        </>
                        }
                        {user ? (
                            <div className="siteheader_clickable register"
                                onClick={(e) => {
                                    logout(e);
                                    navigate('/login')
                                }}
                            >
                                Logout
                            </div>
                        ) : (
                            <>
                                <div className="siteheader_clickable login"
                                    onClick={() => navigate('/login')}
                                >
                                    Login
                                </div>
                                <div className="siteheader_clickable register"
                                    onClick={() => navigate('/register')}
                                >
                                    Signup
                                </div>
                            </>

                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default SiteHeader;