import React, { useEffect, useState } from 'react';
import './PostTests.css';

// Components
import Table from '../../../../components/Table/Table';
import Search from '../../../../components/Users/Search';

// Get test
import { fetchPostTests } from '../../../../utils/firebase/fetchPostTests';

import { collection, endBefore, getDocs, limit, limitToLast, onSnapshot, orderBy, query, startAfter } from "firebase/firestore";
import { db } from '../../../../firebase';


const PostTests = () => {
  const [testsDB, setTestsDB] = useState([]);
  const [loading, setLoading] = useState(true);

  // Firebase pagination
  const [pageSize, setPageSize] = useState(5);
  const [lastVisible, setLastVisible] = useState(null);
  const [firstVisible, setFirstVisible] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [pageId, setPageId] = useState(null);

  const columns = [
    { label: "Edit", accessor: "edit", sortable: true, date: false },
    { label: "Active", accessor: "active", sortable: false, date: false, link: false },
    { label: "Title", accessor: "testTitle", sortable: true, date: false },
    { label: "Post Time", accessor: "postTime", sortable: true, date: true, sortbyOrder: "desc" },
    { label: "Total Loads", accessor: "totalLoads", sortable: true, date: false },
    { label: "Total Views", accessor: "totalViews", sortable: false, date: false, link: false },
    { label: "Likes", accessor: "likes", sortable: false, date: false },
  ];

  const handleGetTests = async () => {
    setLoading(true);
    const testsData = await fetchPostTests(pageSize)
    console.log('tingsDB', testsData);
    setTestsDB(testsData.list);
    window.localStorage.setItem('testsDB', JSON.stringify(testsData.list));
    setLoading(testsData.loaded)
    setFirstVisible(testsData.firstVisible);
    setLastVisible(testsData.lastVisible);
  }


  useEffect(() => {
    handleGetTests()
  }, [pageSize]);

  const nextPage = async () => {

    setLoading(true);

    const q = query(collection(db, "postTests"),
      orderBy("postTime", "desc"),
      startAfter(lastVisible), // Pass the reference
      limit(pageSize));

    const documents = await getDocs(q);
    updateState(documents);
  };

  const previousPage = async () => {

    setLoading(true);

    const q = query(
      collection(db, "postTests"),
      orderBy("postTime", "desc"),
      endBefore(firstVisible), // Pass the reference
      limitToLast(pageSize));

    const documents = await getDocs(q);
    updateState(documents);
  };

  const updateState = (documents) => {
    console.log('document: ', documents);
    if (!documents.empty) {
      const tempPosts = [];
      documents.forEach((document) => {
        tempPosts.push({
          id: document.id,
          ...document.data(),
        });
      });
      setTestsDB(tempPosts);

    }
    if (documents?.docs[0]) {
      setFirstVisible(documents.docs[0]);
    }
    if (documents?.docs[documents.docs.length - 1]) {
      setLastVisible(documents.docs[documents.docs.length - 1]);
    }
    setLoading(false);

  };

  return (
    <div className='posttests__container'>
      <h1 className='posttests__heading'>
        Post Tests</h1>
      <Search />

      <div>
        {/* Fetch new users */}
        <button className='posttests__button' onClick={() => handleGetTests(null)}>
          Fetch new tests
        </button>
      </div>

      <div>
        {/* Fetch new users */}
        <button className='posttests__button' onClick={() =>
          window.location.href = '/newposttest'}>

          CREATE new test
        </button>
      </div>


      {loading ?
        <div>Loading...</div>
        :
        <Table
          caption="Latest Post Tests"
          data={testsDB}
          columns={columns}
          path='test' />
      }

      {/* Show a bar with pagination options */}
      <div className='posttests__pagination'>
        <button className='posttests__button' style={{ width: 100 }}
          onClick={previousPage}
        >Previous</button>
        <button className='posttests__button' style={{ width: 100 }}
          onClick={nextPage}
        >Next</button>

        {/* Select how many on a page */}
        <select
          style={{ width: 'auto', marginLeft: 20, padding: 5 }}
          name="pageSize"
          id="pageSize"
          onChange={(e) => {
            setPageSize(e.target.value)
            // Get the first page of the new size
            // getVideos(pageId, startDate, endDate, e.target.value)
          }}
        >
          <option value="5">5</option>
          <option value="30">30</option>
          <option value="42">42</option>
          <option value="54">54</option>
        </select>
      </div>
    </div >
  );
};

export default PostTests;
