import React, { useState, useEffect } from 'react';
import './Tings.css';

// Components
import Search from '../../../../components/Users/Search';
import Table from '../../../../components/Table/Table';

// Get users
import { fetchTodaysTings } from '../../../../utils/firebase/fetchTodaysTings';
import { collection, getDocs, limit, orderBy, query, where } from 'firebase/firestore';
import { db } from '../../../../firebase';

const Tings = () => {
  const [tingsDB, setTingsDB] = useState([]);
  const [loading, setLoading] = useState(true);

  const [filter, setFilter] = useState({
    animal: "",
    userId: "",
    road: "",
    contentType: ""
  });



  const columns = [
    { label: "Edit", accessor: "edit", sortable: true, date: false },
    { label: "Photo", accessor: "postImg", sortable: false, date: false, link: true },
    { label: "Video", accessor: "postVideo", sortable: false, date: false, link: true },
    { label: "Post Time", accessor: "postTime", sortable: true, date: true, sortbyOrder: "desc" },
    { label: "Ting Time", accessor: "tingTime", sortable: true, date: true },
    { label: "Animal", accessor: "animal", sortable: true, date: false },
    { label: "Tinger", accessor: "userId", sortable: true, date: false },
    { label: "Likes", accessor: "likes", sortable: false, date: false },
    { label: "Road", accessor: "roadname", sortable: true, date: false },
    { label: "Location", accessor: "locationDescription", sortable: true, date: false },
    { label: "Latitude", accessor: "latitude", sortable: true, date: false },
    { label: "Longitude", accessor: "longitude", sortable: true, date: false },
    { label: "Content type", accessor: "contentType", sortable: true, date: false },
    { label: "Park", accessor: "park", sortable: true, date: false },
    // { label: "Comments", accessor: "comments", sortable: false, date: false },
  ];

  useEffect(() => {
    fetchTings();
  }, []);

  const fetchTings = async () => {
    setLoading(true);
    const q = query(collection(
      db, "posts"),
      where('deletedTime', '==', null),
      where('postTime', '>', new Date(Date.now() - (1) * 24 * 60 * 60 * 1000)),
      orderBy('postTime', 'desc'),
      limit(50));

    const querySnapshot = await getDocs(q);
    const users = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    setTingsDB(users);
    setLoading(false);
  };

  const handleFilterChange = (event) => {
    setFilter({
      ...filter,
      [event.target.name]: event.target.value,
    });
  };

  const applyFilter = async (event) => {
    event.preventDefault();
    setLoading(true);

    // If no filter, give an error message
    if (!Object.values(filter).some((x) => (x !== null && x !== ''))) {
      alert('Please enter a filter');
      setLoading(false);
      return;
    }

    let q = collection(db, "posts");
    Object.keys(filter).forEach((key) => {
      if (filter[key]) {
        q = query(q, where(key, '==', filter[key]));

      }
    });

    // Add orderBy and limit after filter conditions.
    q = query(
      q,
      orderBy('postTime', 'desc'),
      limit(50)
    );

    const querySnapshot = await getDocs(q);

    const tings = querySnapshot.docs.map((doc) =>
    ({
      id: doc.id,
      ...doc.data(),

    })
    );

    console.log('tings', tings);
    // Sort by postTime
    tings.sort((a, b) => {
      return new Date(b.postTime) - new Date(a.postTime);
    });


    setTingsDB(tings);
    setLoading(false);
  };

  const handleGetTings = async (data) => {
    setLoading(true);
    console.log('data', JSON.parse(data));
    if (data !== null) {
      setTingsDB(JSON.parse(data))
      setLoading(false)
    }
    else {
      const tingsData = await fetchTodaysTings()
      console.log('tingsDB', tingsData);
      setTingsDB(tingsData.list);
      window.localStorage.setItem('tingsDB', JSON.stringify(tingsData.list));
      setLoading(tingsData.loaded)

    }
  }


  return (
    <div className='users__container'>
      <h1 className='users__heading'>
        Tings</h1>
      <Search />
      <form className="filter-bar" onSubmit={applyFilter}>
        <input type="text" name="animal" placeholder="Animal" value={filter.animal} onChange={handleFilterChange} />
        <input type="text" name="userId" placeholder="Tinger ID" value={filter.userId} onChange={handleFilterChange} />
        <input type="text" name="road" placeholder="Road" value={filter.road} onChange={handleFilterChange} />
        <select
          name="contentType"
          value={filter.contentType}
          onChange={handleFilterChange}
        >
          <option value="">Content type</option>
          <option value="image">Image</option>
          <option value="video">Video</option>
          <option value="text">Text</option>
        </select>
        <button type="submit">Apply Filters</button>
      </form>

      <div>
        {/* Fetch new users */}
        <button className='users__button' onClick={() => handleGetTings(null)}>
          Fetch new tings
        </button>
      </div>


      {loading ?
        <div>Loading...</div>
        :
        <Table
          caption="Today's tings."
          data={tingsDB}
          columns={columns}
          path='tings' />
      }
    </div >
  );
};

export default Tings;
